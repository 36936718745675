import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

import type { ChangeEvent } from 'react';

import useFormErrors from '../hooks/useFormErrors';
import { useDispatch, useSelector } from '../hooks/useRedux';
import { useNotifications } from '../providers/NotificationProvider';
import useMissingData from '../hooks/useMissingData';
import Validator from '../lib/Validator';
import Auth from '../lib/AuthService';
import TokenService from '../lib/TokenService';
import { logout } from '../slices/user';

import RequiredForm from '../components/RequiredForm';

const RequiredFormContainer = () => {
  const {
    hasEmail,
    hasPassword,
    email: mdEmail,
    didLoad
  } = useSelector(state => state.user.missingData);

  const [email, setEmail] = useState(mdEmail || '');
  const [password, setPassword] = useState('');
  const [page, setPage] = useState(0);
  const [errors, { clearError, setErrors }] = useFormErrors();

  const [params] = useSearchParams();
  const prefillToken = params.get('token');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notify } = useNotifications();

  const validateEmail = (value: string) => {
    const valid = Validator.email(value);

    if (!valid && !errors.email)
      setErrors({ ...errors, email: 'Email inválido' });
    else if (valid && errors.email) clearError('email');
  };

  const validatePassword = (value: string) => {
    const valid = Validator.password(value);

    if (!valid && !errors.password)
      setErrors({ ...errors, password: 'Contraseña inválida' });
    else if (valid && errors.password) clearError('password');
  };

  const handleTextChange = ({
    target: { name, value }
  }: ChangeEvent<HTMLInputElement>) => {
    switch (name) {
      case 'email':
        validateEmail(value);
        setEmail(value);
        break;
      case 'password':
        validatePassword(value);
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const handleBack = () => setPage(page - 1);

  const handleNext = () => {
    if (page === 0 && (errors.email || !email)) {
      notify([
        {
          title: 'Email inválido',
          message: 'Por favor revise que su email este bien escrito.',
          type: 'alert'
        }
      ]);
      return;
    }
    setPage(page + 1);
  };

  const handleSubmit = async () => {
    try {
      const {
        data: { token }
      } = await axios.post('/onboarding/missingData', {
        email,
        password
      });

      if (!token) throw new Error('Error al crear cuenta.');

      if (TokenService.get()) dispatch(logout());

      TokenService.set(token);
      await Auth.fetchUser();

      navigate('/dashboard');
      Auth.fetchUser();
    } catch (exception) {
      console.log((exception as Error).message);
      notify([{ title: 'Error al crear cuenta', message: '', type: 'bug' }]);
    }
  };

  const handleNextOrSubmit = () => {
    if (page === 0) handleNext();
    else handleSubmit();
  };

  useEffect(() => {
    if (hasEmail || (email && !errors.email)) setPage(1);
  }, [didLoad]);

  useEffect(() => {
    if (mdEmail && !email) setEmail(mdEmail);
  }, [mdEmail]);

  useMissingData(prefillToken);

  return (
    <RequiredForm
      email={email}
      password={password}
      page={page}
      errors={errors}
      hasEmail={hasEmail || (!!email && !errors.email)}
      hasPassword={hasPassword || (!!password && !errors.password)}
      handleTextChange={handleTextChange}
      handleSubmit={handleNextOrSubmit}
      handleBack={handleBack}
    />
  );
};

export default RequiredFormContainer;
