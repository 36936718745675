import { ChangeEvent } from 'react';
import styles from './index.module.scss';

import Feature from './Feature';
import Input from '../Input';
import CheckBox from '../CheckBox';

import { ReactComponent as Logo } from '../../assets/images/paycodeLogo.svg';

import { Dictionary } from '../../types';

type Props = {
  page: 'continue' | 'signup';
  email: string;
  password: string;
  errors: Dictionary<string>;
  acceptedTerms: boolean;
  authorizing: boolean;
  handleTextChange(event: ChangeEvent<HTMLInputElement>): void;
  togglePage(): void;
  authorize(): void;
  toggleTerms(): void;
};

const info = {
  signup: {
    submitText: 'Crear Cuenta',
    switchText: '¿Deseas continuar con tu registro?',
    switchHighlight: '¡Continua Ahora!'
  },
  continue: {
    submitText: 'Continuar Registro',
    switchText: '¿Aun no tienes cuenta?',
    switchHighlight: 'Crear cuenta'
  }
};

const Auth = ({
  page,
  email,
  password,
  errors,
  acceptedTerms,
  authorizing,
  handleTextChange,
  togglePage,
  authorize,
  toggleTerms
}: Props) => {
  const data = info[page];
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Logo className={styles.logo} />
        <p className={styles.topText}>
          No dejes para mañana lo que puedes hacer hoy
        </p>
        <p className={styles.title}>¡Registrate ahora!</p>
        <div className={styles.contactWrapper}>
          <p className={styles.contactQuestion}>¿Aún no te has decidido?</p>
          <a
            href="https://paycode.com.mx/contacto.html"
            className={styles.contactLink}
          >
            Ponte en contacto con un ejecutivo
          </a>
        </div>
        <Input
          id="landingEmail"
          name="email"
          placeholder="Email"
          type="email"
          value={email}
          error={errors.email}
          onChange={handleTextChange}
        />
        <div className={styles.inputSpacer} />
        <Input
          id="landingPassword"
          name="password"
          placeholder="Contraseña"
          value={password}
          error={errors.password}
          type="password"
          showVisibilityToggle
          onChange={handleTextChange}
        />
        {page === 'signup' ? (
          <p className={styles.note}>
            La contraseña debe de tener 8 caracteres incluyendo números,
            minusculas y mayúsculas
          </p>
        ) : null}
        {page === 'signup' ? (
          <div className={styles.termsConditions}>
            <CheckBox className={styles.checkBox} onChange={toggleTerms} />
            <p className={styles.textWrapper}>
              Acepto los&nbsp;
              <a
                href="https://www.paycode.com.mx/terms.html"
                target="_blank"
                rel="noreferrer"
                className={styles.termsButton}
              >
                términos y condiciones
              </a>
            </p>
          </div>
        ) : null}
        <div className={styles.footer}>
          <button
            className={`${styles.primaryButton} ${
              page === 'continue' ? styles.active : ''
            }`}
            type="button"
            onClick={authorize}
            disabled={authorizing || (page === 'signup' && !acceptedTerms)}
          >
            {!authorizing ? data.submitText : 'Autorizando...'}
          </button>
          <button
            className={styles.switchButton}
            type="button"
            onClick={togglePage}
          >
            {data.switchText}{' '}
            <span className={styles.switchHighlight}>
              {data.switchHighlight}
            </span>
          </button>
        </div>
        <p className={styles.featureTitle}>Estás a un paso de:</p>
        <div className={styles.features}>
          <Feature
            description="Aceptar tarjetas físicas y en línea en la misma plataforma"
            icon="card"
          />
          <Feature
            description="Tener un dashboard enfocado en data"
            icon="funnel"
          />
          <Feature
            description="Ofrecer multiples canales de venta como CoDi, tarjeta o SPEI"
            icon="cash"
          />
        </div>
      </div>
    </div>
  );
};

export default Auth;
