import { useEffect, useRef, useState } from 'react';
import axios from 'axios';

import type { Zone, ZonePayload } from '../types';
import ApiParser from '../lib/ApiParser';

type ReturnData = [
  Zone[],
  {
    fetchZones(): Promise<void>;
    createZone(zonePayload: ZonePayload): Promise<Zone>;
  }
];

type Args = {
  autoLoad: boolean;
};

const initialArgs: Args = {
  autoLoad: true
};

const useZones = (
  businessId: string | number,
  args = initialArgs
): ReturnData => {
  const { autoLoad } = args;
  const [zones, setZones] = useState<Zone[]>([]);

  const zoneCountRef = useRef(zones.length);

  const fetchZones = async () => {
    if (
      zoneCountRef.current > 0 ||
      axios.defaults.headers.common.Authorization === undefined
    )
      return;

    try {
      const {
        data: { data, success }
      } = await axios.get(
        `gateway/api/v2/administrator/business/${businessId}/zone?pageSize=100`
      );

      if (!success) throw new Error('Error al cargar zonas.');

      const newZones = ApiParser.parseZones(data);
      setZones(newZones);
      zoneCountRef.current = newZones.length;
    } catch (exception) {
      console.log((exception as Error).message);
    }
  };

  const createZone = async (zonePayload: ZonePayload) => {
    const {
      data: { success, ...serialZone }
    } = await axios.post(
      `gateway/api/v2/administrator/business/${businessId}/zone`,
      zonePayload
    );

    if (!success) throw new Error('Ocurrio un error al crear zona');

    const newZone = ApiParser.parseZone(serialZone);

    setZones(prev => [newZone, ...prev]);
    zoneCountRef.current += 1;

    return newZone;
  };

  useEffect(() => {
    if (autoLoad) fetchZones();
  }, []);

  return [zones, { fetchZones, createZone }];
};

export default useZones;
