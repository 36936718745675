import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import IonIcon from '@reacticons/ionicons';

import styles from './index.module.scss';

import NavActions from './Actions';
import NavAction from './ActionButton';
import SearchBar, { Props as SearchBarProps } from './SearchBar';

import { Dictionary } from '../../types';

const titles: Dictionary<string> = {
  '/dashboard': 'On Boarding',
  '/dashboard/cart': 'Tienda',
  '/dashboard/checkout': 'Checkout',
  '/admin/profiles': 'Perfiles',
  '/admin/sellerReference': 'Referencia del vendedor'
};

type Props = {
  paddingLeft?: boolean;
  withSearchBar?: boolean;
  searchBarProps?: SearchBarProps;
  toggleSidebar?: () => void;
};

const Nav = ({
  paddingLeft,
  withSearchBar,
  searchBarProps,
  toggleSidebar
}: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { scrollY } = useViewportScroll();

  const title = useMemo(() => titles[pathname], [pathname]);

  const backgroundColor = useTransform(
    scrollY,
    [0, 16],
    ['rgba(255,255,255,0)', 'rgba(255,255,255,1)']
  );

  const borderColor = useTransform(
    scrollY,
    [0, 16],
    ['rgba(242,241,246,0)', 'rgba(242,241,246,1)']
  );

  const navBack = () => navigate(-1);
  const navForward = () => navigate(1);

  return (
    <div
      className={styles.container}
      style={paddingLeft ? { paddingLeft: 49 } : { paddingLeft: 321 }}
    >
      <motion.div
        className={styles.nav}
        style={{ backgroundColor, borderColor }}
      >
        <div className={styles.left}>
          <div className={styles.navActions}>
            {toggleSidebar ? (
              <button
                type="button"
                className={`${styles.navButton} ${styles.menu}`}
                onClick={toggleSidebar}
              >
                <IonIcon name="menu" />
              </button>
            ) : null}
            <button
              type="button"
              className={styles.navButton}
              onClick={navBack}
            >
              <IonIcon name="chevron-back" />
            </button>
            <button
              type="button"
              className={styles.navButton}
              onClick={navForward}
            >
              <IonIcon name="chevron-forward" />
            </button>
          </div>
          <p className={styles.title}>{title}</p>
          {withSearchBar ? (
            <>
              <div className={styles.verticalSpacer} />
              <SearchBar {...searchBarProps} />
            </>
          ) : null}
        </div>
      </motion.div>
    </div>
  );
};

Nav.defaultProps = {
  toggleSidebar: undefined,
  paddingLeft: false,
  withSearchBar: false,
  searchBarProps: {}
};

export { NavActions, NavAction };

export default Nav;
