/* eslint-disable react/jsx-wrap-multilines */
import { Routes, Route } from 'react-router-dom';

import App from './containers/App';
import Landing from './containers/Landing';
import DashboardRouter from './containers/DashboardRouter';
import Splash from './containers/AuthSplash';
import RequiredForm from './containers/RequiredForm';
import AdminRouter from './containers/Admin';
import AdminAuth from './containers/Admin/Auth';

import ProtectedRoute from './components/ProtectedRoute';
import NoTokenRoute from './components/NoTokenRoute';

const Router = () => (
  <App>
    <Routes>
      <Route
        path="/admin/login"
        element={
          <NoTokenRoute admin>
            <AdminAuth />
          </NoTokenRoute>
        }
      />
      <Route
        path="/admin/*"
        element={
          <ProtectedRoute admin>
            <AdminRouter />
          </ProtectedRoute>
        }
      />
      <Route path="/admin/*" element={<AdminRouter />} />
      <Route
        path="/dashboard/*"
        element={
          <ProtectedRoute>
            <DashboardRouter />
          </ProtectedRoute>
        }
      />
      <Route path="/splash" element={<Splash />} />
      <Route path="/missingData" element={<RequiredForm />} />
      <Route
        path="/"
        element={
          <NoTokenRoute>
            <Landing />
          </NoTokenRoute>
        }
      />
    </Routes>
  </App>
);

export default Router;
