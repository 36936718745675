import { useEffect, useRef, useState } from 'react';
import axios from 'axios';

import ApiParser from '../lib/ApiParser';

import {
  addCachedPage,
  setTotalPages,
  unshiftProfiles
} from '../slices/profiles';
import { useDispatch, useSelector } from './useRedux';

import { Profile } from '../types';

type ReturnData = [
  Profile[],
  {
    didLoad: boolean;
    totalPages: number;
    fetchProfiles(): Promise<void>;
    searchProfiles(
      searchTerm: string,
      page: number,
      filter?: string
    ): Promise<{ profiles: Profile[]; totalPages: number }>;
  }
];

export const PAGE_SIZE = 15;

const useProfiles = (page: number): ReturnData => {
  const [didLoad, setDidLoad] = useState(false);
  const { profiles, totalPages } = useSelector(state => state.profiles);

  const totalPagesRef = useRef<number>(totalPages);

  const dispatch = useDispatch();

  const _unshiftProfiles = (
    forPage: number,
    newProfiles: Profile | Profile[]
  ) => {
    dispatch(unshiftProfiles({ page: forPage, profiles: newProfiles }));
  };

  const _setTotalPages = (newTotal: number) => {
    if (newTotal === totalPagesRef.current) return;
    totalPagesRef.current = newTotal;
    dispatch(setTotalPages(newTotal));
  };

  const fetchProfiles = async (customPage = page) => {
    if (
      profiles[customPage] ||
      axios.defaults.headers.common.Authorization === undefined
    )
      return;

    setDidLoad(false);
    try {
      const {
        data: { success, data, meta }
      } = await axios.get(
        `gateway/api/v2/administrator/business?page=${customPage}&pageSize=${PAGE_SIZE}&status=processing`
      );

      if (!success) throw new Error('Error fetching profiles');

      const filteredData = data.filter((element: any) => element !== null);
      const newProfiles = ApiParser.parseProfiles(filteredData);
      const { last_page: newTotalPages } = meta;

      dispatch(addCachedPage(customPage));

      _unshiftProfiles(customPage, newProfiles);
      _setTotalPages(newTotalPages);
    } catch (exception) {
      console.log((exception as Error).message);
    } finally {
      setDidLoad(true);
    }
  };

  const searchProfiles = async (
    searchTerm: string,
    customPage: number,
    filter = 'businessname'
  ) => {
    const {
      data: { success, data, meta }
    } = await axios.get(
      `gateway/api/v2/administrator/business?page=${customPage}&pageSize=${PAGE_SIZE}&${filter}=${searchTerm}&status=processing`
    );

    if (!success) throw new Error('Error fetching profiles');

    const filteredData = data.filter((element: any) => element !== null);
    const newProfiles = ApiParser.parseProfiles(filteredData);

    return { profiles: newProfiles, totalPages: meta.last_page };
  };

  useEffect(() => {
    fetchProfiles();
  }, [page]);

  return [
    profiles[page],
    { didLoad, totalPages, fetchProfiles, searchProfiles }
  ];
};

export default useProfiles;
