/* eslint-disable @typescript-eslint/indent */
import type { CSSProperties, ReactNode, SelectHTMLAttributes } from 'react';

import IonIcon from '@reacticons/ionicons';
import styles from './Select.module.scss';

type Props = {
  id: string;
  placeholder: string;
  label?: string;
  children: ReactNode[] | ReactNode;
  invalid?: boolean;
  style?: CSSProperties;
  selectStyle?: CSSProperties;
} & SelectHTMLAttributes<HTMLSelectElement>;

const Select = ({
  id,
  label,
  invalid,
  placeholder,
  style,
  children,
  selectStyle,
  ...selectProps
}: Props) => (
  <div className={styles.wrapper} style={style}>
    <p className={styles.selectTitle}>{placeholder}</p>
    <select
      id={id}
      className={`${styles.select} ${invalid ? styles.invalid : ''}`}
      style={selectStyle}
      {...selectProps}
    >
      {children}
    </select>
    <IonIcon name="chevron-down-outline" className={styles.icon} />
  </div>
);

Select.defaultProps = {
  invalid: false,
  label: '',
  style: undefined,
  selectStyle: undefined
};

export default Select;
