/* eslint-disable no-confusing-arrow */
import { ReactNode } from 'react';

import styles from './Modal.module.scss';

type ChildrenType = ReactNode | ReactNode[];

export type Props = {
  visible: boolean;
  children?: ChildrenType;
  close?: () => void;
};

const Modal = ({ children, visible, close }: Props) =>
  visible ? (
    <div className={styles.container}>
      <button type="button" className={styles.scrim} onClick={close} />
      <div className={styles.content}>{children}</div>
    </div>
  ) : null;

Modal.defaultProps = {
  children: null,
  close: undefined
};

export default Modal;
