import styles from './PaymentMethodCard.module.scss';

import PaymentMethodItem from './PaymentMethodItem';

type items = {
  card: boolean;
  codi: boolean;
  spei: boolean;
  cash: boolean;
};

type Props = {
  items: items;
  title: string;
};

const PaymentMethodCard = ({ items, title }: Props) => (
  <>
    <p className={styles.title}>{title}</p>
    <div className={styles.list}>
      {items.card === true ? (
        <PaymentMethodItem iconName="card" method="card" />
      ) : null}
      {items.codi === true ? (
        <PaymentMethodItem iconName="qr-code" method="codi" />
      ) : null}
      {items.spei === true ? (
        <PaymentMethodItem iconName="business" method="spei" />
      ) : null}
      {items.cash === true ? (
        <PaymentMethodItem iconName="cash" method="cash" />
      ) : null}
    </div>
  </>
);

export default PaymentMethodCard;
