import { useEffect, useState } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import TokenService from '../lib/TokenService';
import useMissingData from '../hooks/useMissingData';
import { useSelector } from '../hooks/useRedux';

import { ReactComponent as Logo } from '../assets/images/paycodeLogo.svg';

const AppSplash = () => {
  const location = useLocation();
  const [params] = useSearchParams();

  const fromPathname = (location.state as any).from.pathname;
  const isAdmin = (location.state as any).admin;

  const storedToken = TokenService.get(isAdmin);

  const didLoad = useSelector(state => state.user.didLoad);
  const missingData = useSelector(state => state.user.missingData);
  const [token, setToken] = useState(storedToken);

  const prefillToken = params.get('token');

  const handleRemoveToken = () => setToken(null);

  useMissingData(prefillToken);

  useEffect(() => {
    TokenService.onRemove = handleRemoveToken;
  }, []);

  if (isAdmin && !token) return <Navigate to="/admin/login" replace />;
  if (isAdmin && token) {
    // TODO: Check roles when endpoint is ready and nav accordingly
    return <Navigate to="/admin/profiles" replace />;
  }

  if (
    prefillToken &&
    missingData.didLoad &&
    (!missingData.hasEmail || !missingData.hasPassword)
  )
    return (
      <Navigate
        to={`/missingData?token=${encodeURIComponent(prefillToken)}`}
        state={{ from: location }}
        replace
      />
    );

  if (!token && !prefillToken)
    return <Navigate to="/" state={{ from: location }} replace />;

  if (token && didLoad && !prefillToken)
    return (
      <Navigate
        to={fromPathname === '/' ? '/dashboard' : fromPathname}
        state={{ from: location }}
        replace
      />
    );

  return (
    <div className="app-splash">
      <Logo width={512} />
    </div>
  );
};

export default AppSplash;
