import axios from 'axios';

interface UploadOptions {
  folder?: string;
  filename?: string;
  bucket?: string;
  onProgress?: (progress: number) => void;
  onFinish?: (s3Url: string) => void;
  onError?: (error?: Error) => void;
}

export default class S3 {
  static async sign(
    filename: string,
    type: string,
    folder = '',
    bucket = 'onboarding'
  ) {
    const response = await axios.get(
      encodeURI(
        `s3/sign?filename=${filename}&filetype=${type}&folder=${folder}&bucket=${bucket}`
      )
    );
    return response.data;
  }

  static async get(s3Url: string) {
    const response = await axios.get(encodeURI(`s3/get?s3Url=${s3Url}`));
    return response.data;
  }

  static async download(s3Url: string) {
    const filename = s3Url.split('/').pop();
    const { signedUrl } = await this.get(s3Url);
    console.log(signedUrl);
    const element = document.getElementById(
      'download_iframe'
    ) as HTMLAnchorElement;

    if (!element) return;

    const res = await fetch(signedUrl);
    const file = await res.blob();
    const downloadUrl = URL.createObjectURL(file);

    element.href = downloadUrl;
    element.download = filename || 'file';
    element.click();

    URL.revokeObjectURL(downloadUrl);
  }

  static async upload(
    file: File,
    { folder, filename, bucket, onProgress, onFinish, onError }: UploadOptions
  ) {
    try {
      const { signedUrl, url } = await this.sign(
        filename || '',
        file.type,
        folder,
        bucket
      );
      const oReq = new XMLHttpRequest();
      oReq.addEventListener('load', () => {
        if (onFinish) onFinish(url);
      });
      oReq.upload.addEventListener(
        'progress',
        ({ loaded, total, lengthComputable }) => {
          if (lengthComputable && onProgress) onProgress(loaded / total);
        }
      );
      oReq.addEventListener('error', () => {
        if (onError) onError();
      });
      oReq.open('PUT', signedUrl);
      oReq.setRequestHeader('Content-Type', file.type);
      oReq.send(file);
    } catch (exception) {
      if (onError) onError(exception as Error);
    }
  }
}
