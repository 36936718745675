import styles from './SkeletonLoader.module.scss';

import Footer from './Footer';
import Header from './Header';
import SkeletonRow from './SkeletonRow';

type Props = {
  page: number;
  totalPages: number;
  rowCount: number;
};

const SkeletonLoader = ({ page, totalPages, rowCount }: Props) => {
  const renderRows = () => {
    const rows = [];

    for (let i = 0; i < rowCount; i += 1) {
      rows.push(<SkeletonRow key={`sk-row-${i}`} />);
    }

    return rows;
  };

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.list}>{renderRows()}</div>
      <Footer currentPage={page} totalPages={totalPages} />
    </div>
  );
};

export default SkeletonLoader;
