import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import styles from './index.module.scss';

import useSegmentWidth from '../../hooks/useSegmentWidth';

import Segment from './Segment';

import { ControlSegment } from '../../types';

type Props = {
  name?: string;
  segments: ControlSegment[];
  value?: unknown;
  className?: string;
  onSelect?: (name: string, value: unknown, index: number) => void;
};

const HORIZONTAL_PADDING = 2;

const SegmentedControl = ({
  name,
  segments,
  value,
  className,
  onSelect
}: Props) => {
  const [_value, setValue] = useState<unknown>();
  const [position, setPosition] = useState(2);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const substractFromTotal = 2;
  const segmentWidth = useSegmentWidth({
    numberOfSegments: segments.length || 1,
    substractFromWidth: HORIZONTAL_PADDING * 2,
    substractFromTotal,
    containerRef
  });

  const setHighlightPositionFromIndex = (index: number) => {
    const newPosition = index * segmentWidth + (2 + index * substractFromTotal);
    setPosition(newPosition);
  };

  const handleSelect = (selectedValue: unknown, index: number) => () => {
    if (onSelect) onSelect(name || '', selectedValue, index);
    if (!value) {
      setValue(selectedValue);
      setHighlightPositionFromIndex(index);
    }
  };

  const updatePositionOnValueChange = () => {
    const index = _.findIndex(segments, ['value', _value]);
    if (index !== -1) setHighlightPositionFromIndex(index);
  };

  useEffect(() => {
    if (value) setValue(value);
  }, [value]);

  useEffect(() => {
    updatePositionOnValueChange();
  }, [_value]);

  const renderSegments = () =>
    segments.map((segment, index) => (
      <Segment
        // eslint-disable-next-line react/no-array-index-key
        key={`${segment.text}-${index}`}
        text={segment.text || ''}
        style={{ width: segmentWidth }}
        onClick={handleSelect(segment.value, index)}
      />
    ));

  return (
    <div ref={containerRef} className={`${styles.container} ${className}`}>
      <div
        className={styles.highlight}
        style={{ left: `${position}px`, width: segmentWidth }}
      />
      {renderSegments()}
    </div>
  );
};

SegmentedControl.defaultProps = {
  value: undefined,
  className: '',
  onSelect: undefined,
  name: ''
};

export default SegmentedControl;
