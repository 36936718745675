import { Fragment } from 'react';

import type { ReactNode } from 'react';

import styles from './Section.module.scss';
import Callout from './Callout';

type Props = {
  title: string;
  notes?: string[];
  children?: ReactNode[] | ReactNode;
  hideDivider?: boolean;
  topSpacer?: boolean;
  observation?: string;
  id: string;
  phoneOrSmaller?: boolean;
};

const Section = ({
  title,
  notes,
  children,
  hideDivider,
  topSpacer,
  observation,
  id,
  phoneOrSmaller
}: Props) => {
  const renderNotes = () =>
    notes &&
    notes.map((note, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={`${note}-${index}`}>
        <div className={styles.noteSpacer} />
        <p className={styles.note}>{note}</p>
      </Fragment>
    ));

  return (
    <div className={styles.container} id={id}>
      <div className={styles.info}>
        {topSpacer ? <div className={styles.topSpacer} /> : null}
        <p className={styles.title}>{title}</p>
        {renderNotes()}
        <div className={styles.dividerSpacer} />
        {!hideDivider ? <div className={styles.divider} /> : null}
      </div>
      <div className={styles.form}>
        {topSpacer ? <div className={styles.topSpacer} /> : null}
        {phoneOrSmaller ? (
          <>
            <p className={styles.title}>{title}</p>
            {renderNotes()}
            <div className={styles.dividerSpacer} />
          </>
        ) : null}
        {observation ? (
          <>
            <Callout message={observation} />
            <div className={styles.dividerSpacer} />
          </>
        ) : null}
        {children}
        <div className={styles.dividerSpacer} />
        {!hideDivider ? <div className={styles.divider} /> : null}
      </div>
    </div>
  );
};

Section.defaultProps = {
  notes: [],
  children: null,
  hideDivider: false,
  topSpacer: true,
  phoneOrSmaller: false,
  observation: undefined
};

export default Section;
