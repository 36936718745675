import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { ReactNode } from 'react';

import Auth from '../lib/AuthService';
import TokenService from '../lib/TokenService';

type Props = {
  children: ReactNode | ReactNode[];
};

const token = TokenService.get();

const App = ({ children }: Props) => {
  const [params] = useSearchParams();
  const prefillToken = params.get('token');

  useEffect(() => {
    if (token && !prefillToken) Auth.fetchUser();
  }, []);

  return <div className="content-container">{children}</div>;
};

export default App;
