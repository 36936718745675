import { motion } from 'framer-motion';

import styles from './SkeletonRow.module.scss';

const SkeletonRow = () => (
  <div className={styles.row}>
    <div className={styles.left}>
      <div className={styles.textPHWrapper}>
        <motion.div
          className={styles.textPH}
          animate={{ opacity: [0.05, 0.3, 0.05] }}
          transition={{ duration: 2, ease: 'easeInOut', repeat: Infinity }}
        />
      </div>
      <div className={styles.textPHWrapper}>
        <motion.div
          className={styles.textPH}
          animate={{ opacity: [0.05, 0.3, 0.05] }}
          transition={{ duration: 2, ease: 'easeInOut', repeat: Infinity }}
        />
      </div>
    </div>
    <div className={styles.right}>
      <motion.div
        className={styles.statusPH}
        animate={{ opacity: [0.05, 0.3, 0.05] }}
        transition={{ duration: 2, ease: 'easeInOut', repeat: Infinity }}
      />
    </div>
    <div className={styles.divider} />
  </div>
);

export default SkeletonRow;
