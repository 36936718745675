/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { Profile } from '../types';

export interface State {
  profiles: Record<string, Profile[]>;
  totalPages: number;
  cachedPages: number[];
}

const initialState: State = {
  profiles: {},
  totalPages: 0,
  cachedPages: []
};

const CACHE_COUNT = 10;

const profilesReducer = createSlice({
  name: 'profilesReducer',
  initialState,
  reducers: {
    pushProfiles(state, action) {
      const { page, profiles } = action.payload;
      if (_.isArray(profiles)) {
        if (state.profiles[page] !== undefined)
          state.profiles[page].concat(profiles);
        else state.profiles[page] = profiles;
      } else if (state.profiles[page] !== undefined)
        state.profiles[page].push(profiles);
      else state.profiles[page] = [profiles];
    },
    unshiftProfiles(state, action) {
      const { page, profiles } = action.payload;
      const currentIterable = state.profiles[page] || [];
      if (_.isArray(profiles))
        state.profiles[page] = [...profiles, ...currentIterable];
      else state.profiles[page].unshift(profiles);
    },
    setTotalPages(state, action) {
      state.totalPages = action.payload;
    },
    addCachedPage(state, action) {
      if (state.cachedPages.length >= CACHE_COUNT) {
        const lastIndex = state.cachedPages.length - 1;
        const condition = action.payload > state.cachedPages[lastIndex];
        const removeIndex = condition ? 0 : lastIndex;
        const newCachedPages = [
          ...state.cachedPages.slice(0, removeIndex),
          ...state.cachedPages.slice(removeIndex + 1)
        ];

        delete state.profiles[state.cachedPages[removeIndex]];

        state.cachedPages = condition
          ? [...newCachedPages, action.payload]
          : [action.payload, ...newCachedPages];
        return;
      }
      state.cachedPages.push(action.payload);
    }
  }
});

export const { pushProfiles, unshiftProfiles, setTotalPages, addCachedPage } =
  profilesReducer.actions;

export default profilesReducer.reducer;
