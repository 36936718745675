import { ButtonHTMLAttributes, useMemo } from 'react';
import styles from './ProfileRow.module.scss';

type Props = {
  email: string;
  businessName: string;
  status: 'PROCESSING' | 'REJECTED' | 'COMPLETE' | 'FILLING';
} & ButtonHTMLAttributes<HTMLButtonElement>;

const statusData = {
  PROCESSING: {
    label: 'Pendiente',
    color: '#FFD60A',
    backgroundColor: 'rgba(255,214,10,0.2)'
  },
  REJECTED: {
    label: 'Rechazado',
    color: '#FF3B30',
    backgroundColor: 'rgba(255,59,48,0.2)'
  },
  COMPLETE: {
    label: 'Completo',
    color: '#55d696',
    backgroundColor: 'rgba(85,214,150, 0.2)'
  },
  FILLING: {
    label: 'Llenando',
    color: '#007AFF',
    backgroundColor: 'rgba(0,122,255, 0.2)'
  }
};

const ProfileRow = ({ email, businessName, status, ...buttonProps }: Props) => {
  const data = useMemo(() => statusData[status || 'FILLING'], [status]);
  return (
    <button type="button" className={styles.row} {...buttonProps}>
      <div className={styles.left}>
        <p className={styles.business}>{businessName}</p>
        <p className={styles.name}>{email}</p>
      </div>
      <div className={styles.right}>
        <div
          className={styles.statusWrapper}
          style={{ backgroundColor: data.backgroundColor }}
        >
          <p className={styles.status} style={{ color: data.color }}>
            {data.label}
          </p>
        </div>
      </div>
      <div className={styles.divider} />
    </button>
  );
};

export default ProfileRow;
