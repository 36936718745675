import { useEffect } from 'react';

const useEvent = (event: string, handler: EventListener, passive = false) => {
  useEffect(() => {
    window.addEventListener(event, handler, passive);
    return () => window.removeEventListener(event, handler);
  }, []);
};

export default useEvent;
