import { useEffect, useState } from 'react';
import IonIcon from '@reacticons/ionicons';

import styles from './QuantityControl.module.scss';

type Props = {
  value?: number;
  onChange?: (newValue: number) => void;
  onIncrement?: (newValue: number) => void;
  onDecrement?: (newValue: number) => void;
};

const Control = ({ value, onIncrement, onDecrement, onChange }: Props) => {
  const [_value, setValue] = useState(0);

  const handleIncrement = () => {
    const newValue = _value + 1;
    if (onIncrement) onIncrement(newValue);
    if (onChange) onChange(newValue);
    if (value === undefined) setValue(newValue);
  };

  const handleDecrement = () => {
    const newValue = _value - 1;
    if (onDecrement) onDecrement(newValue);
    if (onChange) onChange(newValue);
    if (value === undefined) setValue(newValue);
  };

  useEffect(() => {
    if (value !== undefined) setValue(value);
  }, [value]);

  return (
    <div className={`${styles.container} ${_value > 0 ? styles.active : ''}`}>
      <button
        type="button"
        className={`${styles.button} ${_value > 0 ? styles.active : ''}`}
        onClick={handleIncrement}
      >
        Agregar
      </button>
      <button
        type="button"
        className={styles.buttonCounter}
        onClick={handleDecrement}
      >
        {_value === 1 ? <IonIcon name="trash" /> : <IonIcon name="remove" />}
      </button>
      {_value}
      <button
        type="button"
        className={styles.buttonCounter}
        onClick={handleIncrement}
      >
        <IonIcon name="add" />
      </button>
    </div>
  );
};

export default Control;

Control.defaultProps = {
  value: undefined,
  onChange: undefined,
  onIncrement: undefined,
  onDecrement: undefined
};
