import IonIcon from '@reacticons/ionicons';
import { ButtonHTMLAttributes } from 'react';
import styles from './index.module.scss';

type CustomProps = {
  label: string;
};

type Props = ButtonHTMLAttributes<HTMLButtonElement> & CustomProps;

const Chip = ({ label, className, ...buttonProps }: Props) => (
  <button
    type="button"
    className={`${styles.chip} ${className}`}
    {...buttonProps}
  >
    {label}
    <IonIcon name="close-circle" className={styles.icon} />
  </button>
);

export default Chip;
