import IonIcon from '@reacticons/ionicons';
import styles from './Chip.module.scss';

type Props = {
  text: string;
  active: boolean;
};

const Chip = ({ text, active }: Props) => (
  <div className={`${styles.chip} ${active ? styles.active : ''}`}>
    <p className={styles.text}>{text}</p>
    <div className={styles.indicator}>
      {active ? <IonIcon name="checkmark" /> : null}
    </div>
  </div>
);

export default Chip;
