import { useEffect, useMemo } from 'react';
import _ from 'lodash';

type DebounceOptions = {
  leading?: boolean;
  maxWait?: number;
  trailing?: boolean;
};

export default (
  eventFunction: () => void,
  observers: any,
  debounceOptions: DebounceOptions
) => {
  const debounced = useMemo(
    () => _.debounce(eventFunction, 600, debounceOptions),
    observers
  );

  useEffect(
    () => () => {
      debounced.cancel();
    },
    []
  );

  return debounced;
};
