import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

import Store from '../components/Store';
import { NavAction, NavActions } from '../components/Nav';

import { useStoreData } from '../providers/CreateStoreProvider';

const StoreScreen = () => {
  const { products, quantities, setQuantity } = useStoreData();
  const navigate = useNavigate();

  const handleQuantityChange = (id: string) => (newValue: number) => {
    setQuantity(id, newValue);
  };

  const sumQuantities = useMemo(() => {
    let sum = 0;
    Object.values(quantities).forEach(value => {
      sum += value;
    });
    return sum;
  }, [quantities]);

  const navToCheckout = () => {
    if (sumQuantities !== 0) navigate('/dashboard/checkout');
  };

  return (
    <>
      <NavActions>
        <NavAction
          preset="primary"
          icon="cart"
          text="Carrito"
          badge={sumQuantities}
          onClick={navToCheckout}
        />
      </NavActions>
      <Store
        products={products}
        quantities={quantities}
        handleQuantityChange={handleQuantityChange}
      />
    </>
  );
};

export default StoreScreen;
