/* eslint-disable no-confusing-arrow */
import styles from './SaveNotification.module.scss';

type Props = {
  visible: boolean;
  saving: boolean;
  save(): void;
  discard(): void;
};

const SaveNotification = ({ visible, saving, save, discard }: Props) => (
  <div className={`${styles.container} ${visible ? styles.visible : ''}`}>
    <p className={styles.question}>
      Tienes cambios sin guardar. ¿Deseas guardar tus cambios?
    </p>
    <div className={styles.actions}>
      <button
        type="button"
        className={styles.discardButton}
        disabled={saving}
        onClick={discard}
      >
        Descartar
      </button>
      <button
        type="button"
        className={styles.saveButton}
        disabled={saving}
        onClick={save}
      >
        {!saving ? 'Guardar' : 'Guardando...'}
      </button>
    </div>
  </div>
);

export default SaveNotification;
