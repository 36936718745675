import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import type { ReactNode } from 'react';

import { useSelector } from '../hooks/useRedux';
import TokenService from '../lib/TokenService';

type Props = {
  admin?: boolean;
  children: ReactNode | ReactNode[];
};

const NoTokenRoute = ({ admin, children }: Props): any => {
  const didLoad = useSelector(state => state.user.didLoad);
  const location = useLocation();
  const [params] = useSearchParams();

  const prefillToken = encodeURIComponent(params.get('token') || '');
  const token = TokenService.get(admin);

  if (admin && token)
    return (
      <Navigate to="/splash" state={{ from: location, admin: true }} replace />
    );

  if ((token && !didLoad) || prefillToken)
    return (
      <Navigate
        to={`/splash${prefillToken ? `?token=${prefillToken}` : ''}`}
        state={{ from: location }}
        replace
      />
    );

  return children;
};

NoTokenRoute.defaultProps = {
  admin: false
};

export default NoTokenRoute;
