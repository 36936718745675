import {
  ReactNode,
  createContext,
  useState,
  useEffect,
  Fragment,
  useMemo,
  useContext
} from 'react';

import ProcessingModal from '../components/OnBoarding/ProcessingModal';
import PopupSellerReference from '../components/OnBoarding/SellerReferencePopup.tsx';

type Props = {
  children: ReactNode | ReactNode[];
};

type PopupType = 'sellerReference' | 'processingModal';

type PopupData = {
  type: PopupType;
  visible: boolean;
  popupProps: any;
};

type ContextData = {
  showPopup(popupType: PopupType, popupProps?: any): void;
};

export const PopupContext = createContext<ContextData>({
  showPopup: () => {}
});

export const PopupProvider = ({ children }: Props) => {
  const [popups, setPopups] = useState<PopupData[]>([]);

  const showPopup = (type: PopupType, popupProps: any) =>
    setPopups([{ type, visible: true, popupProps }, ...popups]);

  const close = (index: number) => () => {
    setPopups([
      ...popups.slice(0, index),
      { ...popups[index], visible: false },
      ...popups.slice(index + 1)
    ]);
    setTimeout(() => {
      setPopups([...popups.slice(0, index), ...popups.slice(index + 1)]);
    }, 200);
  };

  const getComponentForType = (type: PopupType) => {
    switch (type) {
      case 'sellerReference':
        return PopupSellerReference;
      case 'processingModal':
        return ProcessingModal;
      default:
        return Fragment;
    }
  };

  const renderPopups = () =>
    popups.map(({ type, visible, popupProps }, index) => {
      const Component = getComponentForType(type);
      return (
        <Component
          // eslint-disable-next-line react/no-array-index-key
          key={`${type}-${index}`}
          {...popupProps}
          close={popupProps.preventClose ? undefined : close(index)}
          visible={visible}
        />
      );
    });

  const contextValue = useMemo(() => ({ showPopup }), [popups]);

  useEffect(() => {
    const containsClass = document.body.classList.contains('no-scroll');

    if (popups.length === 0 && containsClass)
      document.body.classList.remove('no-scroll');
    else if (popups.length > 0 && !containsClass)
      document.body.classList.add('no-scroll');
  }, [popups]);

  return (
    <PopupContext.Provider value={contextValue}>
      {popups.length > 0 ? (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            overflow: 'hidden',
            right: 0,
            top: 0,
            zIndex: 998
          }}
        >
          {renderPopups()}
        </div>
      ) : null}
      {children}
    </PopupContext.Provider>
  );
};

export const usePopups = () => useContext(PopupContext);
