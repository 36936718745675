import { useEffect, useRef, useState } from 'react';
import IonIcon from '@reacticons/ionicons';

import type { ChangeEvent, InputHTMLAttributes } from 'react';

import styles from './SearchBar.module.scss';

export type Props = {
  searchTerm?: string;
  onSearchChange?: (newTerm: string) => void;
} & InputHTMLAttributes<HTMLInputElement>;

const SearchBar = ({ searchTerm, onSearchChange, ...inputProps }: Props) => {
  const [_searchTerm, setSearchTerm] = useState(searchTerm || '');

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleTextChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    if (searchTerm !== undefined && onSearchChange) {
      onSearchChange(value);
      return;
    }

    setSearchTerm(value);
  };

  const handleClick = () => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  };

  useEffect(() => {
    if (searchTerm !== undefined) setSearchTerm(searchTerm);
  }, [searchTerm]);

  return (
    <button type="button" className={styles.container} onClick={handleClick}>
      <div className={styles.iconWrapper}>
        <IonIcon name="search" />
      </div>
      <input
        ref={inputRef}
        className={styles.input}
        placeholder="Buscar"
        value={_searchTerm}
        onChange={handleTextChange}
        {...inputProps}
      />
    </button>
  );
};

SearchBar.defaultProps = {
  searchTerm: undefined,
  onSearchChange: undefined
};

export default SearchBar;
