import { useEffect, useRef } from 'react';
import axios from 'axios';
import _ from 'lodash';

import ApiParser from '../lib/ApiParser';
import { useDispatch, useSelector } from './useRedux';
import { setBranches, unshiftBranch, updateBranch } from '../slices/branches';

import type { Branch, BranchPayload } from '../types';

type ReturnData = [
  Branch[],
  {
    fetchBranches(): Promise<void>;
    createBranch(branchPayload: BranchPayload): Promise<Branch>;
    updateBranch(branchId: number, properties: Partial<Branch>): void;
    commitBranchUpdates(): Promise<void>;
  }
];

type Args = {
  autoLoad?: boolean;
};

const initialArgs: Args = {
  autoLoad: true
};

const useBranches = (
  businessId: string | number,
  args = initialArgs
): ReturnData => {
  const { autoLoad } = args;
  const { branches, businessId: currentBusinessId } = useSelector(
    state => state.branches
  );
  const dispatch = useDispatch();

  const branchCountRef = useRef(branches.length);
  const unchangedBranchesRef = useRef(branches);

  const _setBranches = (newBranches: Branch[]) => {
    const payload = { id: businessId, branches: newBranches };
    dispatch(setBranches(payload));
  };

  const modifyBranch = (branch: Branch, index: number) => {
    const payload = { index, branch };
    dispatch(updateBranch(payload));
  };

  const _unshiftBranch = (newBranch: Branch) => {
    dispatch(unshiftBranch(newBranch));
  };

  const fetchBranches = async () => {
    if (
      (businessId === currentBusinessId && branches.length > 0) ||
      branchCountRef.current > 0 ||
      axios.defaults.headers.common.Authorization === undefined
    )
      return;
    try {
      const {
        data: { data, success }
      } = await axios.get(
        `/gateway/api/v2/administrator/business/${businessId}/branch?pageSize=100`
      );

      if (!success) throw new Error('Error al cargar sucursales.');

      const newBranches = ApiParser.parseBranches(data);
      _setBranches(newBranches);
      branchCountRef.current = newBranches.length;
      unchangedBranchesRef.current = newBranches;
    } catch (exception) {
      console.log((exception as Error).message);
    }
  };

  const _updateBranch = (branchId: number, properties: Partial<Branch>) => {
    const index = _.findIndex(branches, ['id', branchId]);
    if (index === -1) return;
    const branch = branches[index];
    const modifiedBranch = { ...branch, ...properties };

    modifyBranch(modifiedBranch, index);
  };

  const commitBranchUpdates = async () => {};

  const createBranch = async (branchPayload: BranchPayload) => {
    const {
      data: { success, ...newSerialBranch }
    } = await axios.post(
      `/gateway/api/v2/administrator/business/${businessId}/branch`,
      branchPayload
    );

    if (!success) throw new Error('Ocurrio un error al crear sucursal');

    const newBranch = ApiParser.parseBranch(newSerialBranch);

    _unshiftBranch(newBranch);
    unchangedBranchesRef.current = [newBranch, ...unchangedBranchesRef.current];
    branchCountRef.current += 1;

    return newBranch;
  };

  useEffect(() => {
    if (autoLoad) fetchBranches();
  }, []);

  return [
    branches,
    {
      fetchBranches,
      createBranch,
      updateBranch: _updateBranch,
      commitBranchUpdates
    }
  ];
};

export default useBranches;
