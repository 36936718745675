import IonIcon from '@reacticons/ionicons';
import { useEffect, useState } from 'react';
import styles from './CheckBox.module.scss';

export type Props = {
  name?: string;
  checked?: boolean;
  className?: string;
  bubbleClickToParent?: boolean;
  onChange?: (name: string, checked: boolean) => void;
};

const CheckBox = ({
  name,
  checked,
  className,
  bubbleClickToParent,
  onChange
}: Props) => {
  const [_checked, setChecked] = useState(!!checked);

  const handleClick = () => {
    const newChecked = !_checked;

    if (onChange) onChange(name || '', newChecked);
    if (checked === undefined) setChecked(newChecked);
  };

  useEffect(() => {
    setChecked(!!checked);
  }, [checked]);

  return !bubbleClickToParent ? (
    <button
      type="button"
      className={`${styles.container} ${className} ${
        _checked ? styles.checked : ''
      }`}
      onClick={handleClick}
    >
      <div className={`${styles.inner} ${_checked ? styles.checked : ''}`} />
      <IonIcon
        name="checkmark"
        className={`${styles.icon} ${_checked ? styles.checked : ''}`}
      />
    </button>
  ) : (
    <div
      className={`${styles.container} ${className} ${
        _checked ? styles.checked : ''
      }`}
    >
      <div className={`${styles.inner} ${_checked ? styles.checked : ''}`} />
      <IonIcon
        name="checkmark"
        className={`${styles.icon} ${_checked ? styles.checked : ''}`}
      />
    </div>
  );
};

CheckBox.defaultProps = {
  checked: undefined,
  className: '',
  onChange: undefined,
  bubbleClickToParent: false,
  name: ''
};

export default CheckBox;
