import { useNavigate } from 'react-router-dom';

import styles from './OptionRow.module.scss';

type Props = {
  text: string;
  hrefId: string;
  active: boolean;
};

const OptionRow = ({ text, hrefId, active }: Props) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/admin/profile#${hrefId}`);
  };

  return (
    <button
      type="button"
      className={`${styles.container} ${active ? styles.active : ''}`}
      onClick={handleOnClick}
    >
      <div className={styles.wrapper}>
        <p className={styles.text}>{text}</p>
      </div>
    </button>
  );
};

export default OptionRow;
