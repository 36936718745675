import { useEffect, useState } from 'react';
import axios from 'axios';

import _ from 'lodash';
import ApiParser from '../lib/ApiParser';

import { ProfileData } from '../types';

type ReturnData = [
  Partial<ProfileData>,
  {
    fetchProfileData(): Promise<void>;
  }
];

type Args = {
  autoLoad?: boolean;
};

const defaultArgs = { autoLoad: true };

const useProfileData = (
  id: number | string,
  args: Args = defaultArgs
): ReturnData => {
  const { autoLoad } = { ...defaultArgs, ...args };
  const [didLoad, setDidLoad] = useState(false);
  const [profileData, setProfileData] = useState<Partial<ProfileData>>({});

  const fetchProfileData = async () => {
    if (axios.defaults.headers.common.Authorization === undefined) return;
    try {
      const { data } = await axios.get(
        `/gateway/api/v2/administrator/business/${id}`
      );

      const parseData = ApiParser.parseAdminData(data);
      setProfileData(parseData);
    } catch (exception) {
      console.log((exception as Error).message);
    } finally {
      if (!didLoad) setDidLoad(true);
    }
  };

  useEffect(() => {
    if (autoLoad && _.isEmpty(profileData)) fetchProfileData();
  }, []);

  return [profileData, { fetchProfileData }];
};

export default useProfileData;
