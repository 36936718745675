/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { Branch } from '../types';

export interface State {
  businessId: string;
  branches: Branch[];
}

const initialState: State = {
  businessId: '',
  branches: []
};

const branchesSlice = createSlice({
  name: 'branchesSlice',
  initialState,
  reducers: {
    setBranches(state, action) {
      const { id, branches } = action.payload;
      state.businessId = id;
      state.branches = branches;
    },
    setBusinessId(state, action) {
      state.businessId = action.payload;
    },
    updateBranch(state, action) {
      const { branch, index } = action.payload;
      state.branches = [
        ...state.branches.slice(0, index),
        branch,
        ...state.branches.slice(index + 1)
      ];
    },
    unshiftBranch(state, action) {
      state.branches.unshift(action.payload);
    }
  }
});

export const { setBranches, setBusinessId, updateBranch, unshiftBranch } =
  branchesSlice.actions;

export default branchesSlice.reducer;
