/* eslint-disable @typescript-eslint/indent */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useProfiles, { PAGE_SIZE } from '../../hooks/useProfiles';
import useDebounce from '../../hooks/useDebounce';

import Profiles from '../../components/Admin/Profiles';
import Empty from '../../components/Admin/Profiles/Empty';
import SkeletonLoader from '../../components/Admin/Profiles/SkeletonLoader';

import { Profile } from '../../types';

const PAGE_KEY = 'PROFILES_PAGE';

type Props = {
  searchTerm: string;
};

const ProfilesScreen = ({ searchTerm }: Props) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [searchMode, setSearchMode] = useState(false);
  const [searchTotalPages, _setSearchTotalPages] = useState(1);
  const [searchResults, setSearchResults] = useState<Record<
    string,
    Profile[]
  > | null>(null);
  const [profiles, { didLoad, totalPages, searchProfiles }] = useProfiles(page);

  const searchTotalPagesRef = useRef<number>(1);

  const setSearchTotalPages = (newPages: number) => {
    searchTotalPagesRef.current = newPages;
    _setSearchTotalPages(newPages);
  };

  const handleSearch = async () => {
    const { profiles: newProfiles, totalPages: newTotalPages } =
      await searchProfiles(searchTerm, searchPage);

    if (newTotalPages !== searchTotalPagesRef.current)
      setSearchTotalPages(newTotalPages);
    setSearchResults(prev => ({ ...prev, [searchPage]: newProfiles }));
  };

  const debouncedSearch = useDebounce(
    handleSearch,
    [searchPage, searchTotalPages, searchTerm],
    {
      trailing: true
    }
  );

  const handleProfileClick = (id: number) => () => {
    navigate('/admin/profile', { state: { id } });
  };

  const handlePageChange = (newPage: number) => {
    if (searchMode) {
      setSearchPage(newPage);
    }
    setPage(() => {
      window.sessionStorage.setItem(PAGE_KEY, `${newPage}`);
      return newPage;
    });
  };

  const results: Profile[] | null = useMemo(() => {
    if (searchResults === null) return null;
    return searchResults[searchPage] || null;
  }, [searchPage, searchResults]);

  useEffect(() => {
    const storedPage = window.sessionStorage.getItem(PAGE_KEY);
    if (storedPage) setPage(parseInt(storedPage, 10));
  }, []);

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch();
      if (!searchMode) setSearchMode(true);
    }

    if (!searchTerm && searchMode) {
      setSearchMode(false);
      setSearchPage(1);
      setSearchResults(null);
    }
  }, [searchTerm, searchMode]);

  if (searchMode) {
    if (results === null)
      return (
        <SkeletonLoader
          page={searchPage}
          totalPages={searchTotalPages}
          rowCount={PAGE_SIZE}
        />
      );

    if (results.length === 0)
      return <Empty customText="No se encontro ningun negocio." />;

    return (
      <Profiles
        profiles={results || []}
        page={searchPage}
        totalPages={searchTotalPages}
        setPage={handlePageChange}
        onProfileClick={handleProfileClick}
      />
    );
  }

  if ((profiles === undefined || profiles.length === 0) && !didLoad)
    return (
      <SkeletonLoader
        page={page}
        totalPages={totalPages}
        rowCount={PAGE_SIZE}
      />
    );

  if ((profiles === undefined || profiles.length === 0) && didLoad)
    return <Empty />;

  return (
    <Profiles
      profiles={profiles || []}
      page={page}
      totalPages={totalPages}
      setPage={handlePageChange}
      onProfileClick={handleProfileClick}
    />
  );
};

export default ProfilesScreen;
