import { useEffect, useState, useRef } from 'react';

type ReturnData = [number, { yOffsets: number[] }];

export default (elementIDs: string[]): ReturnData => {
  const [highlightedIndex, _setHighlightedIndex] = useState<number>(0);
  const [offsets, _setOffsets] = useState<number[]>([]);
  const [unrenderedIDs, _setUnrenderedIDs] = useState<string[]>([]);

  const offsetsRef = useRef(offsets);
  const highlightedRef = useRef(highlightedIndex);
  const unrenderedRef = useRef(unrenderedIDs);

  const setOffsets = (data: number[]) => {
    offsetsRef.current = data;
    _setOffsets(data);
  };

  const setHighlightedIndex = (data: number) => {
    highlightedRef.current = data;
    _setHighlightedIndex(data);
  };

  const setUnrenderedIDs = (data: string[]) => {
    unrenderedRef.current = data;
    _setUnrenderedIDs(data);
  };

  const getOffset = (id: string) => {
    const element = document.getElementById(id);
    if (element !== null) {
      const { top } = element.getBoundingClientRect();
      return top - 80;
    }
    return -1;
  };

  const getOffsets = () => {
    const newOffsets: number[] = [];
    const unrendered: string[] = [];
    elementIDs.forEach(id => {
      const offset = getOffset(id);
      if (offset !== -1) newOffsets.push(offset);
      else unrendered.push(id);
    });

    setUnrenderedIDs(unrendered);
    setOffsets(newOffsets);
  };

  const handleUnrenderedCheck = (scrollY: number) => {
    const newOffsets: number[] = [];
    const newUnrendered: string[] = [];
    unrenderedRef.current.forEach(id => {
      const offset = getOffset(id);
      if (offset !== -1) newOffsets.push(offset + scrollY);
      else newUnrendered.push(id);
    });

    setUnrenderedIDs(newUnrendered);
    setOffsets([...offsetsRef.current, ...newOffsets]);
  };

  const handleScroll = () => {
    const scrollY = window.pageYOffset;

    for (let index = 0; index <= offsetsRef.current.length; index += 1) {
      const offset = offsetsRef.current[index];
      const nextOffset =
        index === offsetsRef.current.length - 1
          ? window.innerHeight
          : offsetsRef.current[index + 1];
      if (
        scrollY >= offset &&
        scrollY < nextOffset &&
        highlightedRef.current !== index
      ) {
        setHighlightedIndex(index);
        handleUnrenderedCheck(scrollY);
        return;
      }
    }
  };

  useEffect(() => {
    setTimeout(() => getOffsets(), 25);
  }, [elementIDs]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return [highlightedIndex, { yOffsets: offsets }];
};
