import S3 from '../lib/S3';
import { useDispatch, useSelector } from './useRedux';
import {
  setTransfer,
  setTransferProperty,
  pushToQueue,
  shiftQueue,
  setActiveTransferCount
} from '../slices/queue';

import { Dictionary, TransferItem } from '../types';

type AddOptions = {
  id: string;
  transferType: 'upload' | 'download';
  filename?: string;
  folder?: string;
};

type ReturnType = [
  Dictionary<TransferItem>,
  (file: File, options: AddOptions) => void
];

const fileMap = new Map();

export default (
  onTransferFinish?: (id: string, s3Url: string) => void
): ReturnType => {
  const transfers = useSelector(state => state.queue.transfers);
  const queue = useSelector(state => state.queue.queue);
  const activeTransferCount = useSelector(
    state => state.queue.activeTransferCount
  );

  const dispatch = useDispatch();

  const checkQueueOnFinish = () => {
    if (queue.length === 0) return;

    const item = transfers[queue[0]];
    dispatch(shiftQueue());

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (item.transferType === 'upload') upload(item);
  };

  const upload = (transferItem: TransferItem) => {
    const { id, folder, filename } = transferItem;
    const file = fileMap.get(id);

    if (!file) return;

    dispatch(setActiveTransferCount(activeTransferCount + 1));
    dispatch(
      setTransferProperty({
        id,
        property: 'status',
        value: 'transfering'
      })
    );

    const handleProgress = (progress: number) => {
      dispatch(
        setTransferProperty({ id, property: 'progress', value: progress })
      );
    };

    const handleError = () => {
      dispatch(setTransferProperty({ id, property: 'status', value: 'error' }));
      dispatch(setActiveTransferCount(activeTransferCount - 1));
    };

    const handleFinish = (s3Url: string) => {
      dispatch(setActiveTransferCount(activeTransferCount - 1));
      checkQueueOnFinish();
      dispatch(setTransferProperty({ id, property: 'status', value: 'done' }));

      if (onTransferFinish) onTransferFinish(id, s3Url);
    };

    S3.upload(file, {
      folder,
      filename,
      onProgress: handleProgress,
      onError: handleError,
      onFinish: handleFinish
    });
  };

  // const downloadS3File = async (s3Url: string) => {
  //   const siognedUrl = await S3.get(s3Url);
  //   const anchor = document.createElement('a');
  // };

  const addToQueue = async (file: File, options: AddOptions) => {
    const { id, transferType, folder, filename } = options;
    const item: TransferItem = {
      id,
      progress: 0,
      transferType,
      status: 'queue',
      folder,
      filename: filename || file.name
    };

    fileMap.set(id, file);

    await dispatch(setTransfer({ id, transfer: item }));

    if (activeTransferCount < 2 && queue.length === 0) {
      if (transferType === 'upload') upload(item);
    } else dispatch(pushToQueue(id));
  };

  return [transfers, addToQueue];
};
