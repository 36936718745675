/* eslint-disable no-param-reassign */
import axios from 'axios';

import TokenService from './lib/TokenService';

import { apiUrl } from './constants/Urls';

axios.defaults.baseURL = `${apiUrl}/api/`;

axios.interceptors.request.use(config => {
  const token = TokenService.get();

  if (token && config && config.headers && !config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${token}`;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  return config;
});

axios.interceptors.response.use(undefined, async error => {
  const { response, config } = error;
  const isAdmin = config.url.includes('api/v2/administrator/');
  const storedToken = TokenService.get(isAdmin);

  if (
    storedToken &&
    response &&
    response.status === 401 &&
    config &&
    !config.__isRetry
  ) {
    try {
      const token = await TokenService.refreshToken(isAdmin);
      config.headers.Authorization = `Bearer ${token}`;

      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } catch (exception) {
      return Promise.reject(error);
    }

    config.__isRetry = true;
    return axios(config);
  }
  return Promise.reject(error);
});
