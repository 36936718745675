import { Children, useEffect, useState } from 'react';
import type { ReactNode } from 'react';

import styles from './Actions.module.scss';

type ChildrenType = ReactNode[] | ReactNode;

type Props = {
  children: ChildrenType;
};

const Actions = ({ children }: Props) => {
  const [customChildren, setCustomChildren] = useState<ChildrenType>();

  const makeChildren = () => {
    const newChildren = Children.map(children, (child, index) => {
      if (index !== Children.count(children) - 1)
        return (
          <>
            {child}
            <div className={styles.spacer} />
          </>
        );

      return child;
    });

    setCustomChildren(newChildren);
  };

  useEffect(() => {
    makeChildren();
  }, [children]);

  return <div className={styles.actionContainer}>{customChildren}</div>;
};

export default Actions;
