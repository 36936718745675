import { useLocation } from 'react-router-dom';

import IonIcon from '@reacticons/ionicons';
import styles from './SideBar.module.scss';

import useScrollHighlight from '../../../hooks/useScrollHighlight';
import useProfileData from '../../../hooks/useProfileData';

import OptionRow from './OptionRow';

type Props = {
  display?: boolean;
  toggleSidebar?: () => void;
};

type LocationState = {
  id: string | number;
};

const sections = [
  'adminLogData',
  'adminInterest',
  'adminPersonalInfo',
  'adminBusinessInfo',
  'adminSalesVolume',
  'adminProviders',
  'adminFiles'
];

const SideBar = ({ display, toggleSidebar }: Props) => {
  const location = useLocation();
  const state = location.state as LocationState;
  const [highlitedIndex] = useScrollHighlight(sections);
  const [profileData] = useProfileData(state.id);

  return (
    <div className={`${styles.container} ${display ? styles.display : ''}`}>
      <div className={styles.top}>
        <button
          type="button"
          className={styles.closeButton}
          onClick={toggleSidebar}
        >
          <IonIcon name="close" />
        </button>
        <OptionRow
          text="Datos de Registro"
          hrefId="adminLogData"
          active={highlitedIndex === 0}
        />
        <OptionRow
          text="Interés"
          hrefId="adminInterest"
          active={highlitedIndex === 1}
        />
        <OptionRow
          text="Datos Personales"
          hrefId="adminPersonalInfo"
          active={highlitedIndex === 2}
        />
        <OptionRow
          text="Datos de su empresa"
          hrefId="adminBusinessInfo"
          active={highlitedIndex === 3}
        />
        <OptionRow
          text="Volumen de Ventas"
          hrefId="adminSalesVolume"
          active={highlitedIndex === 4}
        />
        <OptionRow
          text="Servicios y Proveedores"
          hrefId="adminProviders"
          active={highlitedIndex === 5}
        />
        <OptionRow
          text="Archivos cargados"
          hrefId="adminFiles"
          active={highlitedIndex === 6}
        />
      </div>
      <div className={styles.bottom}>
        <p className={styles.name}>{profileData.business?.name}</p>
      </div>
    </div>
  );
};

SideBar.defaultProps = {
  display: false,
  toggleSidebar: undefined
};

export default SideBar;
