import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import axios from 'axios';

import type { KeyboardEvent } from 'react';

import { useProfileData } from '../providers/ProfileDataProvider';
import useTransfer from '../hooks/useTransfer';
import { useNotifications } from '../providers/NotificationProvider';
import { usePopups } from '../providers/PopupProvider';
import { useDispatch, useSelector } from '../hooks/useRedux';
import useCompareDeviceWidth from '../hooks/useCompareDeviceWidth';
import Validator from '../lib/Validator';
import { setStatus } from '../slices/user';

import OnBoarding from '../components/OnBoarding';
import { NavAction, NavActions } from '../components/Nav';
import SaveNotification from '../components/OnBoarding/SaveNotification';

import { Dictionary } from '../types';

const OnBoardingContainer = () => {
  const {
    profileData,
    didChange,
    modifyData,
    modifyDocumentation,
    serializePorfileData,
    discardChanges,
    updateChanges,
    checkDidComplete
  } = useProfileData();
  const { status } = useSelector(state => state.user.user);
  const [compare, { windowWidth }] = useCompareDeviceWidth();
  const [saving, setSaving] = useState(false);
  const [sending, setSending] = useState(false);

  const dispatch = useDispatch();

  const { notify } = useNotifications();
  const { showPopup } = usePopups();

  const phoneOrSmaller = useMemo(() => compare('<=phone'), [windowWidth]);

  const getSpreadData = (parts: string[], index: number) => {
    let access: any = profileData;

    for (let i = 0; i < index; i += 1) {
      const key = parts[i];
      if (!access[key]) return access;
      access = access[key];
    }

    return access || {};
  };

  const makePropertiesFromName = (
    name: string,
    value: any
  ): Dictionary<any> => {
    const parts = name.split('.');
    let properties = {};

    for (let i = parts.length - 1; i >= 0; i -= 1) {
      const key = parts[i];
      const spreadData = getSpreadData(parts, i);
      properties = {
        ...spreadData,
        [key]: i !== parts.length - 1 ? properties : value
      };
    }

    return properties;
  };

  const handleChange = (name: string, value: unknown) => {
    const properties = name.includes('.')
      ? makePropertiesFromName(name, value)
      : { [name]: value };
    modifyData(properties);
  };

  const onTransferFinish = (id: string, s3Url: string) =>
    modifyDocumentation({ [id]: s3Url });

  const [transfers, addToQueue] = useTransfer(onTransferFinish);

  const restrictNumeric = (
    value: string,
    type: 'integer' | 'decimal' = 'integer'
  ) => {
    const regex =
      type === 'integer' ? /^\d+$/ : /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;

    if (!regex.test(value)) throw new Error('Invalid number');
  };

  const restrictTextInputs = (name: string, value: string) => {
    if (name === 'phone') restrictNumeric(value);
    if (name === 'avgTicket' || name === 'topTicket')
      restrictNumeric(value, 'decimal');
  };

  const handleTextChange = ({
    target: { name, value }
  }: ChangeEvent<HTMLInputElement>) => {
    try {
      if (value !== '') restrictTextInputs(name, value);
      handleChange(name, value);
    } catch (exception) {
      console.log((exception as Error).message);
    }
  };

  const handleSegmentSelect = (name: string, value: unknown) =>
    handleChange(name, value);

  const handleFilesChange = (files: FileList, id?: string) => {
    if (!id) return;
    const file = files[0];
    const extension = file.name.split('.').pop();
    const filename = `${profileData.email}-${id}.${extension}`;

    addToQueue(file, { id, filename, folder: 'Files', transferType: 'upload' });
  };

  const validatePaymentOptions = () => {
    if (!profileData.paymentOptions) return null;
    const keys = Object.keys(profileData.paymentOptions);

    for (let i = 0; i < keys.length; i += 1) {
      // @ts-ignore
      if (profileData.paymentOptions[keys[i]]) return true;
    }

    return false;
  };

  const validate = () => {
    const newErrors: Dictionary<string> = {};

    if (profileData.business?.rfc && !Validator.rfc(profileData.business.rfc))
      newErrors.rfc = 'RFC inválido.';

    if (profileData.curp && !Validator.curp(profileData.curp))
      newErrors.curp = 'CURP inválido.';

    if (!validatePaymentOptions())
      newErrors.paymentOptions = 'Mínimo una opción de cobro es necesaria.';

    const values = Object.values(newErrors);

    if (values.length > 0) {
      notify([
        {
          title: 'Error al validar los campos',
          message: values[0],
          type: 'alert'
        }
      ]);
      throw new Error('Error al validar los campos.');
    }
  };

  const handleSave = async (): Promise<boolean> => {
    setSaving(true);
    try {
      const serializedData = serializePorfileData();
      await axios.put('paycode/onboarding/user/update', serializedData);
      const didComplete = checkDidComplete();

      updateChanges();

      return didComplete;
    } catch (exception) {
      const { message } = exception as Error;
      if (message !== 'Error al validar los campos.')
        notify([
          {
            type: 'bug',
            title: 'Occurrio un error al guardar',
            message:
              'Error desconocido, por favor intentelo mas tarde o pongase en contacto con soporte.'
          }
        ]);
    } finally {
      setSaving(false);
    }

    return false;
  };

  const handleSubmitForReview = async () => {
    setSending(true);
    try {
      validate();

      let shouldSend = true;

      if (didChange) shouldSend = await handleSave();

      if (shouldSend) {
        const {
          data: { success: statusSuccess }
        } = await axios.put('paycode/onboarding/business', {
          status: 'processing'
        });

        if (!statusSuccess) {
          return;
        }

        dispatch(setStatus('processing'));

        notify([
          {
            type: 'success',
            title: '!Información enviada!',
            message:
              'Revisaremos su información de nuevo para poder finalizar el alta de su cuenta.'
          }
        ]);
      }
    } catch (exception) {
      const { message } = exception as Error;
      if (message !== 'Error al validar los campos.')
        notify([
          {
            type: 'bug',
            title: 'Occurrio un error al guardar',
            message:
              'Error desconocido, por favor intentelo mas tarde o pongase en contacto con soporte.'
          }
        ]);
    } finally {
      setSending(false);
    }
  };

  const handleSubmit = async () => {
    setSaving(true);
    try {
      validate();

      const didComplete = await handleSave();

      if (didComplete && status !== 'rejected') {
        const {
          data: { success: statusSuccess }
        } = await axios.put('paycode/onboarding/business', {
          status: 'processing'
        });

        if (statusSuccess) {
          dispatch(setStatus('processing'));

          notify([
            {
              type: 'success',
              title: '!Información enviada!',
              message:
                'Revisaremos su información para poder finalizar el alta de su cuenta.'
            }
          ]);

          return;
        }
      }

      notify([
        {
          type: 'success',
          title: '¡Información guardada!',
          message: 'Se actualizo su información correctamente.'
        }
      ]);
    } catch (exception) {
      const { message } = exception as Error;
      if (message !== 'Error al validar los campos.')
        notify([
          {
            type: 'bug',
            title: 'Occurrio un error al guardar',
            message:
              'Error desconocido, por favor intentelo mas tarde o pongase en contacto con soporte.'
          }
        ]);
    } finally {
      setSaving(false);
    }
  };

  const handleKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      handleSubmit();
    }
  };

  const renderNavActions = () => {
    if (status !== 'rejected')
      return (
        <NavActions>
          <NavAction
            preset="primary"
            icon="save"
            text={!saving ? 'Guardar' : 'Guardando...'}
            disabled={saving}
            onClick={handleSubmit}
          />
        </NavActions>
      );

    return (
      <NavActions>
        <NavAction
          preset="secondary"
          icon="save"
          text={!saving ? 'Guardar' : 'Guardando...'}
          disabled={saving}
          onClick={handleSubmit}
        />
        <NavAction
          preset="primary"
          icon="send"
          text={!sending ? 'Enviar a revisión' : 'Enviando...'}
          disabled={sending}
          onClick={handleSubmitForReview}
        />
      </NavActions>
    );
  };

  useEffect(() => {
    if (status === 'complete')
      window.location.replace('https://plataforma.paycode.com.mx');
    if (status === 'processing')
      showPopup('processingModal', { visible: true, preventClose: true });
  }, []);

  return (
    <>
      {renderNavActions()}
      <OnBoarding
        transfers={transfers}
        phoneOrSmaller={phoneOrSmaller}
        handleTextChange={handleTextChange}
        handleKeyDown={handleKeyDown}
        handleSegmentSelect={handleSegmentSelect}
        handleChange={handleChange}
        handleFilesChange={handleFilesChange}
      />
      <SaveNotification
        visible={didChange}
        saving={saving}
        save={handleSubmit}
        discard={discardChanges}
      />
    </>
  );
};

export default OnBoardingContainer;
