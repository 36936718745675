import type { HTMLAttributes } from 'react';
import styles from './Callout.module.scss';

type Props = {
  color?: string;
  title?: string;
  message?: string;
} & HTMLAttributes<HTMLDivElement>;

const Callout = ({ color, title, message, ...props }: Props) => (
  <div className={styles.container} {...props}>
    <div className={styles.content}>
      {title ? (
        <p className={styles.title} style={{ color }}>
          {title}
        </p>
      ) : null}
      {message ? (
        <p className={styles.message} style={{ color }}>
          {message}
        </p>
      ) : null}
    </div>
    <div className={styles.indicator} style={{ backgroundColor: color }} />
    <div className={styles.bg} style={{ backgroundColor: color }} />
  </div>
);

Callout.defaultProps = {
  color: '#FF3B30',
  message: '',
  title: ''
};

export default Callout;
