import { useCallback, useEffect, useState } from 'react';
import IonIcon from '@reacticons/ionicons';

import type { ChangeEvent, CSSProperties } from 'react';
import styles from './UploadFileRow.module.scss';
import S3 from '../lib/S3';

type Props = {
  id: string;
  progress?: number;
  label: string;
  status?: 'queue' | 'transfering' | 'error' | 'done' | 'none';
  style?: CSSProperties;
  disabled?: boolean;
  download?: boolean;
  s3Url?: string;
  acceptFiles?: string;
  onFilesChange?: (files: FileList, id?: string) => void;
};

const TransferFileRow = ({
  id,
  label,
  status,
  progress,
  style,
  disabled,
  download,
  s3Url,
  acceptFiles,
  onFilesChange
}: Props) => {
  const [displayError, setDisplayError] = useState(false);

  const handleDownload = async () => {
    if (!s3Url) return;

    S3.download(s3Url);
  };

  const handleFilesChange = ({
    target: { files }
  }: ChangeEvent<HTMLInputElement>) => {
    if (!files) return;
    if (onFilesChange) onFilesChange(files, id);
  };

  const makeIconClassName = useCallback(
    (baseClassName: string) =>
      `${baseClassName} ${displayError ? styles.error : ''} ${
        status === 'done' ? styles.done : ''
      } ${status === 'transfering' ? styles.transfering : ''}`,
    [status, displayError]
  );

  useEffect(() => {
    if (status === 'error') {
      setDisplayError(true);
      setTimeout(() => setDisplayError(false), 3000);
    }
  }, [status]);

  return (
    <label htmlFor={id} className={styles.container} style={style}>
      {status === 'transfering' ? (
        <div
          className={styles.progressWrapper}
          style={{ width: `${(progress || 0) * 100}%` }}
        >
          <div className={styles.progressLine} />
        </div>
      ) : null}
      <p className={styles.label}>{label}</p>
      <div className={styles.transferIconWrapper}>
        <IonIcon
          name={download ? 'cloud-download' : 'cloud-upload'}
          className={makeIconClassName(styles.uploadIcon)}
        />
        <IonIcon name="close" className={makeIconClassName(styles.errorIcon)} />
        <IonIcon
          name="checkmark"
          className={makeIconClassName(styles.doneIcon)}
        />
      </div>
      {!download ? (
        <input
          id={id}
          type="file"
          onChange={handleFilesChange}
          className={styles.hiddenInput}
          disabled={disabled}
          accept={acceptFiles}
        />
      ) : (
        <button
          id={id}
          type="button"
          onClick={handleDownload}
          className={styles.hiddenInput}
        />
      )}
    </label>
  );
};

TransferFileRow.defaultProps = {
  status: 'none',
  style: undefined,
  progress: 0,
  onFilesChange: undefined,
  disabled: false,
  download: false,
  s3Url: undefined,
  acceptFiles: undefined
};

export default TransferFileRow;
