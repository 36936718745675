import axios from 'axios';
import styles from './RejectProfileModal.module.scss';

import { useNotifications } from '../../providers/NotificationProvider';

import Modal, { Props as ModalProps } from '../Modal';

import { Observations } from '../../types';

type Props = {
  id: string | number;
  observations: Observations;
  goBack: () => void;
} & ModalProps;

const RejectProfileModal = ({
  id,
  observations,
  goBack,
  close,
  ...modalProps
}: Props) => {
  const { notify } = useNotifications();

  const validate = () => {
    const errors: Record<string, string> = {};
    let hasAtLeastOneObservation = false;

    Object.values(observations).forEach(value => {
      if (value) hasAtLeastOneObservation = true;
    });

    if (!hasAtLeastOneObservation)
      errors.observations =
        'Debes escribir por lo menos una observacion para rechazar un perfil.';

    const values = Object.values(errors);
    if (values.length > 0) {
      notify([
        {
          title: 'Error al validar.',
          message: values[0],
          type: 'alert'
        }
      ]);
      throw new Error('Error al validar campos');
    }
  };

  const handleSubmit = async () => {
    try {
      validate();

      const payload = {
        status: 'rejected',
        observations
      };

      const {
        data: { success, errors }
      } = await axios.put(
        `/gateway/api/v2/administrator/business/${id}`,
        payload
      );

      if (!success) {
        const error =
          errors.length > 0
            ? errors[0]
            : 'Ocurrio un error al completar este perfil.';
        throw new Error(error);
      }

      if (close) close();
      goBack();
    } catch (exception) {
      console.log((exception as Error).message);
    }
  };

  const renderObservations = () =>
    Object.keys(observations).map(key => {
      // @ts-ignore
      const value = observations[key];

      return value ? <p className={styles.observation}>* {value}</p> : null;
    });

  return (
    <Modal {...modalProps} close={close}>
      <div className={styles.container}>
        <p className={styles.title}>Rechazar perfil</p>
        {renderObservations()}
      </div>
      <div className={styles.footer}>
        <button className={styles.tertiaryButton} type="button" onClick={close}>
          Cancelar
        </button>
        <button
          className={styles.primaryButton}
          type="button"
          onClick={handleSubmit}
        >
          Rechazar
        </button>
      </div>
    </Modal>
  );
};

export default RejectProfileModal;
