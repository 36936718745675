import IonIcon from '@reacticons/ionicons';

import { ChangeEvent, useEffect, useRef } from 'react';

import styles from './index.module.scss';

import Input from '../Input';
import Chip from './Chip';

import { Dictionary } from '../../types';

type Props = {
  email: string;
  password: string;
  page: number;
  errors: Dictionary<string>;
  hasEmail: boolean;
  hasPassword: boolean;
  handleTextChange(event: ChangeEvent): void;
  handleSubmit(): void;
  handleBack(): void;
};

const RequiredForm = ({
  email,
  password,
  page,
  errors,
  hasEmail,
  hasPassword,
  handleTextChange,
  handleSubmit,
  handleBack
}: Props) => {
  const scrollDivRef = useRef<HTMLDivElement | null>(null);

  const scrollOnPageChange = () => {
    if (!scrollDivRef.current) return;

    scrollDivRef.current.scrollTo({
      left: scrollDivRef.current.offsetWidth * page,
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    scrollOnPageChange();
  }, [page]);

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        {page > 0 ? (
          <button
            type="button"
            className={styles.backButton}
            onClick={handleBack}
          >
            <IonIcon name="arrow-back" />
          </button>
        ) : null}
        <p className={styles.title}>Información requerida</p>
        <div className={styles.chips}>
          <Chip text="Email" active={hasEmail} />
          <Chip text="Contraseña" active={hasPassword} />
        </div>
        <div ref={scrollDivRef} className={styles.horizontalScrollWrapper}>
          <div className={styles.inputWrapper}>
            <p className={styles.description}>
              Detectamos que hace falta información requerida para crear tu
              cuenta. Introduce tu email para continuar con tu registro.
            </p>
            <Input
              id="missingEmail"
              placeholder="Email"
              name="email"
              value={email}
              error={errors.email}
              onChange={handleTextChange}
            />
          </div>
          <div className={styles.inputWrapper}>
            <p className={styles.description}>
              Introduce tu contraseña para continuar con tu registro. La
              contraseña debe de tener 8 caracteres incluyendo números,
              minusculas y mayúsculas
            </p>
            <Input
              id="missingPassword"
              placeholder="Contraseña"
              name="password"
              value={password}
              error={errors.password}
              type="password"
              showVisibilityToggle
              onChange={handleTextChange}
            />
          </div>
        </div>
        <button type="button" className={styles.button} onClick={handleSubmit}>
          <p className={styles.text}>
            {page !== 1 ? 'Continuar' : 'Crear Cuenta'}
          </p>
          {page !== 1 ? (
            <IonIcon name="arrow-forward" className={styles.icon} />
          ) : null}
          <div className={styles.scrim} />
        </button>
      </div>
    </div>
  );
};

export default RequiredForm;
