import { useState } from 'react';
import IonIcon from '@reacticons/ionicons';

import type {
  ChangeEvent,
  CSSProperties,
  InputHTMLAttributes,
  ReactNode,
  SelectHTMLAttributes
} from 'react';

import styles from './ZoneSelect.module.scss';

type Children = ReactNode[] | ReactNode;

type Props = {
  id: string;
  label: string;
  actionText?: string;
  applyActionText?: string;
  className?: string;
  style?: CSSProperties;
  children: Children;
  selectedValue?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  selectProps?: SelectHTMLAttributes<HTMLSelectElement>;
  onApply?: (value: string) => void;
  onChange?: (value: string | null) => void;
};

type InputProps = {
  applyActionText?: string;
  apply(): void;
  cancel(): void;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = ({ applyActionText, apply, cancel, ...props }: InputProps) => (
  <div className={styles.inputWrapper}>
    <input className={styles.input} {...props} placeholder="" />
    <div className={styles.inputActions}>
      <button type="button" className={styles.applyButton} onClick={apply}>
        {applyActionText}
      </button>
      <button type="button" className={styles.cancelButton} onClick={cancel}>
        <IonIcon name="close" />
      </button>
    </div>
  </div>
);

const ZoneSelect = ({
  id,
  label,
  style,
  actionText,
  className,
  applyActionText,
  inputProps,
  selectProps,
  children,
  selectedValue,
  onApply,
  onChange
}: Props) => {
  const [displayInput, setDisplayInput] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleSelectChange = ({
    target: { value }
  }: ChangeEvent<HTMLSelectElement>) => {
    if (value === '#specialAction') {
      if (onChange) onChange(null);
      setDisplayInput(true);
      return;
    }

    if (onChange) onChange(value);
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event;
    if (inputProps?.onChange) inputProps.onChange(event);

    setInputValue(value);
  };

  const handleApply = () => {
    if (onApply) onApply(inputValue);

    setInputValue('');
    setDisplayInput(false);
  };

  const handleCancel = () => {
    setInputValue('');
    setDisplayInput(false);
  };

  return (
    <label htmlFor={id} className={styles.container}>
      <div className={`${styles.wrapper} ${className}`} style={style}>
        {displayInput ? (
          <>
            <p
              className={`${styles.inputTitle} ${
                inputValue ? styles.withValue : ''
              }`}
            >
              {inputProps?.placeholder}
            </p>
            <Input
              id={id}
              applyActionText={applyActionText}
              {...inputProps}
              onChange={handleTextChange}
              apply={handleApply}
              cancel={handleCancel}
            />
          </>
        ) : (
          <>
            <p className={styles.title}>{label}</p>
            <select
              id={id}
              className={styles.select}
              {...selectProps}
              value={selectedValue}
              onChange={handleSelectChange}
            >
              {children}
              <option value="#specialAction">{actionText}</option>
            </select>
          </>
        )}
      </div>
    </label>
  );
};

Input.defaultProps = {
  applyActionText: 'Crear'
};

ZoneSelect.defaultProps = {
  actionText: 'Crear opción',
  applyActionText: 'Crear',
  className: '',
  inputProps: {},
  selectProps: {},
  style: {},
  selectedValue: undefined,
  onChange: undefined,
  onApply: undefined
};

export default ZoneSelect;
