/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
import { ChangeEvent } from 'react';
import styles from './index.module.scss';

import ProductRow from './ProductRow';
import CheckBox from '../CheckBoxRow';
import InputSegment from './InputSegment';

import { Product, Quantities, FormData } from '../../types';

type Props = {
  check: boolean;
  billingData: FormData;
  shippingData: FormData;
  products: Product[];
  quantities: Quantities;
  onTextChange(event: ChangeEvent<HTMLInputElement>): void;
  onCheckChange(): void;
};

const CheckOut = ({
  check,
  billingData,
  shippingData,
  products,
  quantities,
  onTextChange,
  onCheckChange
}: Props) => {
  const renderProducts = () =>
    products.map((product, index) =>
      // @ts-ignore
      quantities[product.id] > 0 ? (
        <ProductRow
          // @ts-ignore
          count={quantities[product.id]}
          name={product.name}
          price={product.price}
          img={product.image}
          isLast={index < products.length - 1}
        />
      ) : null
    );

  const sumPrices = () => {
    let total = 0;
    products.forEach(product => {
      // @ts-ignore
      const counter = quantities[product.id];
      if (counter !== 0) total += product.price * counter;
    });

    return total;
  };

  return (
    <div className={styles.container}>
      <div className={styles.billingData}>
        <p className={styles.text}>Datos de facturación</p>
        <div className={styles.inputSpacer} />
        <InputSegment
          onTextChange={onTextChange}
          type="billing"
          data={billingData}
        />
        <p className={`${styles.text} ${styles.headers}`}>Datos de envio</p>
        <CheckBox
          text="Usar datos de facturacion"
          onChange={onCheckChange}
          checked={check}
        />
        <div className={styles.inputSpacer} />
        {!check ? (
          <InputSegment
            onTextChange={onTextChange}
            type="shipping"
            data={shippingData}
          />
        ) : null}
        <p className={`${styles.text} ${styles.headers}`}>Forma de pago</p>
        <button type="button" className={styles.finishPurchase}>
          Finalizar Compra
        </button>
      </div>
      <div className={styles.productData}>
        <div className={styles.products}>
          {renderProducts()}
          <div className={styles.divider} />
        </div>
        <div className={styles.amounts}>
          <div className={styles.wrapper}>
            <p className={styles.totalText}>Subtotal</p>
            <div className={styles.priceWrapper}>
              <p className={styles.price}>
                {`$${sumPrices().toLocaleString()}`}
              </p>
              <p className={styles.currency}>MXN</p>
            </div>
          </div>
          <div className={styles.wrapper}>
            <p className={styles.totalText}>Envio</p>
            <p className={styles.price}>GRATIS</p>
          </div>
          <div className={styles.divider} />
        </div>
        <div className={styles.total}>
          <p className={styles.totalText}>Total</p>
          <div className={styles.priceWrapper}>
            <p className={styles.price}>{`$${sumPrices().toLocaleString()}`}</p>
            <p className={styles.currency}>MXN</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOut;
