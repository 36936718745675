import styles from './Empty.module.scss';

type Props = {
  customText?: string;
};

const Empty = ({ customText }: Props) => (
  <div className={styles.container}>
    <p className={styles.empty}>
      {customText || 'No hay ningun perfil listo para procesar.'}
    </p>
  </div>
);

Empty.defaultProps = {
  customText: ''
};

export default Empty;
