import IonIcon from '@reacticons/ionicons';
import styles from './index.module.scss';

type Props = {
  description: string;
  icon: 'card' | 'funnel' | 'cash';
};

const Feature = ({ description, icon }: Props) => (
  <div className={styles.feature}>
    <div className={styles.featureIcon}>
      <div className={styles.iconWrapper}>
        <IonIcon name={icon} />
      </div>
    </div>
    <p className={styles.featureDescription}>{description}</p>
  </div>
);

export default Feature;
