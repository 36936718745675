import type { CSSProperties } from 'react';

import _ from 'lodash';

import styles from './FormData.module.scss';

type Props = {
  id?: string;
  label: string;
  value: string | null | string[] | boolean;
  style?: CSSProperties;
};

const FormData = ({ label, value, style, id }: Props) => {
  const handleValue = () => {
    if (value === null) return '';
    if (value === true) return 'SI';
    if (value === false) return 'NO';
    if (_.isArray(value)) return value?.join(', ');
    return value;
  };

  return (
    <div
      className={`${styles.container} ${
        id === 'question' ? styles.question : ''
      }`}
      style={style}
    >
      <p className={styles.label}>{label}</p>
      <p
        className={`${styles.value} ${value === '1' ? styles.yes : ''} ${
          value === '0' ? styles.no : ''
        }`}
      >
        {handleValue()}
      </p>
    </div>
  );
};

FormData.defaultProps = {
  id: '',
  style: {}
};

export default FormData;
