import styles from './ProductRow.module.scss';
import QuantityControl from './QuantityControl';

type Props = {
  backgroundColor: string;
  image: string;
  name: string;
  type: string;
  price: number;
  quantity: number;
  onQuantityChange?: (newValue: number) => void;
};

const ProductRow = ({
  backgroundColor,
  image,
  name,
  type,
  price,
  quantity,
  onQuantityChange
}: Props) => (
  <div className={styles.container} style={{ backgroundColor }}>
    <div className={styles.data}>
      <div className={styles.imageWrapper}>
        <img src={image} alt="imagen" />
      </div>
      <div className={styles.textWrapper}>
        <p className={`${styles.text} ${styles.name}`}>{name}</p>
        <p className={`${styles.text} ${styles.type}`}>{type}</p>
        <p className={`${styles.text} ${styles.price}`}>
          ${price.toLocaleString()} <span>MXN</span>
        </p>
      </div>
    </div>
    <QuantityControl value={quantity} onChange={onQuantityChange} />
  </div>
);

ProductRow.defaultProps = {
  onQuantityChange: undefined
};

export default ProductRow;
