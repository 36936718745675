import { combineReducers } from '@reduxjs/toolkit';

import branches from './slices/branches';
import profiles from './slices/profiles';
import queue from './slices/queue';
import user from './slices/user';

const combinedReducer = combineReducers({
  branches,
  profiles,
  queue,
  user
});

const rootReducer = (state: any, action: any) => {
  // eslint-disable-next-line no-param-reassign
  if (action.type === 'userSlice/logout') state = undefined;
  return combinedReducer(state, action);
};

export default rootReducer;
