import { ChangeEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useProfileData from '../../hooks/useProfileData';

import ProfileScreen from '../../components/Admin/Profile';
import SideBar from '../../components/Admin/Profile/SideBar';
import { ProfileData } from '../../types';
import { NavAction, NavActions } from '../../components/Nav';
import CompleteModal from '../../components/Admin/CompleteModal';
import RejectModal from '../../components/Admin/RejectProfileModal';

type Props = {
  phoneOrSmaller?: boolean;
  displaySideBar?: boolean;
};

type LocationState = {
  id: string | number;
};

const Profile = ({ phoneOrSmaller, displaySideBar }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = location.state as LocationState;
  const [profileData] = useProfileData(id);
  const [displayComplete, setDisplayComplete] = useState(false);
  const [displayReject, setDisplayReject] = useState(false);

  const [observations, setObservations] = useState({
    register: '',
    interes: '',
    personal: '',
    company: '',
    sales: '',
    services: '',
    files: ''
  });

  const { pathname } = useLocation();

  const handleTextChange = ({
    target: { name, value }
  }: ChangeEvent<HTMLInputElement>) => {
    setObservations({ ...observations, [name]: value });
  };

  const openComplete = () => setDisplayComplete(true);
  const closeComplete = () => setDisplayComplete(false);

  const openReject = () => setDisplayReject(true);
  const closeReject = () => setDisplayReject(false);

  const handleBack = () => navigate(-1);

  return (
    <>
      <NavActions>
        <NavAction
          preset="secondary-black"
          text="Rechazar"
          onClick={openReject}
        />
        <NavAction preset="primary" text="Completar" onClick={openComplete} />
      </NavActions>
      {pathname === '/admin/profile' ? (
        <SideBar display={displaySideBar} />
      ) : null}
      <ProfileScreen
        handleTextChange={handleTextChange}
        phoneOrSmaller={phoneOrSmaller}
        observations={observations}
        adminData={profileData as ProfileData}
      />
      <CompleteModal
        id={id}
        paymentOptions={profileData.paymentOptions || {}}
        visible={displayComplete}
        goBack={handleBack}
        close={closeComplete}
      />
      <RejectModal
        id={id}
        observations={observations}
        visible={displayReject}
        goBack={handleBack}
        close={closeReject}
      />
    </>
  );
};

Profile.defaultProps = {
  phoneOrSmaller: false,
  displaySideBar: true
};

export default Profile;
