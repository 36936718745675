import styles from './CheckBoxRow.module.scss';

import CheckBox, { Props as CheckBoxProps } from './CheckBox';

type CustomProps = {
  text: string;
};

type Props = CustomProps & CheckBoxProps;

const CheckBoxRow = ({ text, ...checkboxProps }: Props) => {
  const handleClick = () => {
    const { checked, name, onChange } = checkboxProps;
    const newChecked = !checked;

    if (onChange) onChange(name || '', newChecked);
  };

  return (
    <button type="button" className={styles.container} onClick={handleClick}>
      <p className={styles.text}>{text}</p>
      <CheckBox {...checkboxProps} bubbleClickToParent />
    </button>
  );
};

export default CheckBoxRow;
