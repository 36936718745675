import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import _ from 'lodash';
import equal from 'fast-deep-equal/es6';

import type { ReactNode } from 'react';

import { useDispatch, useSelector } from '../hooks/useRedux';
import { setProfileData as setReduxData } from '../slices/user';

import type { Dictionary, ProfileData, TransferItem } from '../types';
import { setTransfer } from '../slices/queue';

type Props = {
  children: ReactNode | ReactNode[];
};

type CompleteMap = Dictionary<{ count: number; maxCount: number }>;

type ContextData = {
  profileData: Partial<ProfileData>;
  completeMap: CompleteMap;
  documentation: Dictionary<string>;
  didChange: boolean;
  modifyData(properties: Dictionary<any>): void;
  modifyDocumentation(newDocs: Dictionary<string>): void;
  serializePorfileData(): void;
  discardChanges(): void;
  updateChanges(): void;
  checkDidComplete(): boolean;
};

export const ProfileDataContext = createContext<ContextData>({
  profileData: {},
  completeMap: {},
  documentation: {},
  didChange: false,
  modifyData: () => {},
  modifyDocumentation: () => {},
  serializePorfileData: () => {},
  discardChanges: () => {},
  updateChanges: () => {},
  checkDidComplete: () => false
});

let staticDocumentation: Dictionary<string> | null = null;

const fileKeysByInteres = {
  Físico: ['ine', 'ineBack', 'proofOfAddress', 'accountStatus'],
  Moral: [
    'ine',
    'ineBack',
    'proofOfAddress',
    'accountStatus',
    'fiscalSituation',
    'fiscalSituationBusiness',
    'consitutiveAct',
    'publicRegistry',
    'powerOfAttorney',
    'fotoBusinessIn',
    'fotobusinessOut'
  ]
};

export const ProfileDataProvider = ({ children }: Props) => {
  const reduxProfileData = useSelector(state => state.user.profileData);
  const [profileData, setProfileData] = useState<Partial<ProfileData>>({});
  const [documentation, setDocumentation] = useState<Dictionary<string>>({});
  const [didChange, setDidChange] = useState(false);
  const [completeMap, setCompleteMap] = useState<CompleteMap>({});

  const maxCounts = useMemo(() => {
    const data = _.isEmpty(profileData) ? reduxProfileData : profileData;

    return {
      interes: 1,
      personalInfo: 5,
      businessInfo: data.interes === 'Moral' ? 8 : 5,
      salesVolume: 3,
      providers: 5,
      files: data.interes === 'Moral' && data.paymentOptions?.spei ? 11 : 4
    };
  }, [reduxProfileData, profileData]);

  const dispatch = useDispatch();

  const counter = (data: any, startCount = 0) => {
    let count = startCount;

    Object.keys(data).forEach(key => {
      if (data[key]) count += 1;
    });

    return count;
  };

  const checkInteres = (data: Object) => {
    let count = 0;
    Object.values(data).forEach(value => {
      if (value) count += 1;
    });

    if (count !== 0) return 1;
    return 0;
  };

  const checkDocumentation = (interes: 'Físico' | 'Moral' = 'Físico') => {
    let count = 0;
    const keys = fileKeysByInteres[interes];

    keys.forEach(key => {
      const docIndex = _.findIndex(reduxProfileData.documentation, [
        `${key}`,
        key
      ]);
      if (
        docIndex !== -1 ||
        key === 'fotoBusinessIn' ||
        key === 'fotobusinessOut'
      )
        count += 1;
    });

    return count;
  };

  const makeCompleteMap = useCallback(() => {
    const data = _.isEmpty(profileData) ? reduxProfileData : profileData;

    const personalInfo = {
      name: data.name,
      lastName: data.lastName,
      lastNameM: data.lastNameM,
      curp: data.curp,
      phone: data.phone
    };

    const businessInfo = {
      ...data.business,
      alias: undefined,
      name: data.interes === 'Moral' ? data.business?.name : undefined
    };

    const salesVolume = {
      topTicket: data.topTicket,
      avgTicket: data.avgTicket,
      turnover: data.turnover
    };

    return {
      interes: {
        count: checkInteres(data.paymentOptions || {}),
        maxCount: maxCounts.interes
      },
      personalInfo: {
        count: counter(personalInfo),
        maxCount: maxCounts.personalInfo
      },
      businessInfo: {
        count: counter(businessInfo, data.interes === 'Moral' ? 3 : 1),
        maxCount: maxCounts.businessInfo
      },
      salesVolume: {
        count: counter(salesVolume),
        maxCount: maxCounts.salesVolume
      },
      providers: { count: 5, maxCount: maxCounts.providers },
      files: {
        count: checkDocumentation(data.interes),
        maxCount: maxCounts.files
      }
    };
  }, [reduxProfileData, profileData, maxCounts]);

  const initializeCompleteMap = () => {
    const newCompleteMap = makeCompleteMap();
    setCompleteMap(newCompleteMap);
  };

  const updateCompleteMapOnInteresChange = () => {
    const newCompleteMap = makeCompleteMap();
    setCompleteMap(newCompleteMap);
  };

  const modifyData = (properties: Dictionary<any>) => {
    setProfileData({ ...profileData, ...properties });
  };

  const modifyDocumentation = (newDocs: Dictionary<string>) =>
    setDocumentation({ ...documentation, ...newDocs });

  const parseDocumentation = () => {
    const parsedDocumentation: Dictionary<string> = {};
    profileData.documentation?.forEach((doc: any) => {
      let fileKey = '';

      Object.keys(doc).forEach(key => {
        if (key !== 'valid' && key !== 'name') fileKey = key;
      });

      parsedDocumentation[fileKey] = doc[fileKey];
    });

    if (staticDocumentation === null) staticDocumentation = parsedDocumentation;
    setDocumentation(parsedDocumentation);
  };

  const serializeDocumentation = () => {
    const newDocs: any[] = [];

    Object.keys(documentation).forEach(key => {
      newDocs.push({
        type_doc: key,
        url: documentation[key] || '',
        is_valid: false
      });
    });

    return newDocs;
  };

  const serializePorfileData = () => {
    const { paymentOptions, business, ...restData } = profileData;
    const { cash, ...restPaymentOptions } = paymentOptions!;
    const { alias, ...restBusiness } = business || {};

    const serialDocs = serializeDocumentation();

    return {
      ...restData,
      business: {
        ...restBusiness,
        type: business?.type || 'Agencia de viajes',
        alias_business: alias
      },
      paymentOptions: {
        ...restPaymentOptions,
        cash_in: cash
      },
      documentation: serialDocs
    };
  };

  const mapDocumentationToTransfers = () => {
    const { documentation: pdDocs } = profileData;

    pdDocs?.forEach((doc: any) => {
      let fileKey = '';

      Object.keys(doc).forEach(key => {
        if (key !== 'valid' && key !== 'name') fileKey = key;
      });

      const uri = doc[fileKey];

      if (uri) {
        const transfer: TransferItem = {
          id: fileKey,
          filename: doc.name,
          progress: 0,
          status: 'done',
          transferType: 'upload'
        };

        dispatch(setTransfer({ id: fileKey, transfer }));
      }
    });
  };

  const updateChanges = () => {
    dispatch(setReduxData(profileData));
    staticDocumentation = documentation;
  };

  const checkDidComplete = () => {
    const map = makeCompleteMap();
    const didComplete = Object.values(map).reduce<boolean>(
      (aggregated, obj) => aggregated && obj.count === obj.maxCount,
      true
    );
    return didComplete;
  };

  const discardChanges = () => {
    setProfileData(reduxProfileData);
    if (staticDocumentation !== null) setDocumentation(staticDocumentation);
  };

  const checkForChanges = () => {
    const newDidChange = !(
      equal(profileData, reduxProfileData) &&
      equal(documentation, staticDocumentation)
    );
    setDidChange(newDidChange);
  };

  const value = useMemo(
    () => ({
      profileData,
      completeMap,
      documentation,
      didChange,
      modifyData,
      modifyDocumentation,
      serializePorfileData,
      discardChanges,
      updateChanges,
      checkDidComplete
    }),
    [profileData, didChange, documentation, completeMap]
  );

  useEffect(() => {
    if (_.isEmpty(profileData)) setProfileData(reduxProfileData);
  }, []);

  useEffect(() => {
    if (profileData && profileData.documentation) {
      parseDocumentation();
      mapDocumentationToTransfers();
    }
  }, [profileData.documentation]);

  useEffect(() => {
    if (!_.isEmpty(profileData)) checkForChanges();
  }, [profileData, documentation, reduxProfileData]);

  useEffect(() => {
    initializeCompleteMap();
  }, [reduxProfileData]);

  useEffect(() => {
    if (!_.isEmpty(completeMap)) updateCompleteMapOnInteresChange();
  }, [profileData.interes, profileData.paymentOptions]);

  return (
    <ProfileDataContext.Provider value={value}>
      {children}
    </ProfileDataContext.Provider>
  );
};

export const useProfileData = () => useContext(ProfileDataContext);
