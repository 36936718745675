const calculateCURPValidationDigit = (matchedCurp: string) => {
  const dict = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
  let sum = 0;
  let digit = 0;

  for (let i = 0; i < 17; i += 1)
    sum += dict.indexOf(matchedCurp.charAt(i)) * (18 - i);

  digit = 10 - (sum % 10);
  if (digit === 10) return 0;
  return digit;
};

export default class Validator {
  static email(email: string) {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  static password(password: string) {
    const regex = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])[a-zA-Z0-9]{8,}$/;
    return regex.test(password);
  }

  static curp(curp: string) {
    if (!curp) return false;

    const generalRegex =
      /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    const matches = curp.match(generalRegex);

    if (!matches) return false;

    const digit = calculateCURPValidationDigit(matches[1]);
    if (matches[2] !== `${digit}`) return false;

    return true;
  }

  static rfc(rfc: string) {
    const rfcRegex =
      /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?$/;
    return rfcRegex.test(rfc);
  }
}
