import IonIcon from '@reacticons/ionicons';

import {
  ChangeEvent,
  Fragment,
  InputHTMLAttributes,
  KeyboardEvent,
  useEffect,
  useState
} from 'react';

import styles from './index.module.scss';

import Chip from './Chip';

type CustomProps = {
  chips?: string[];
  icon?: any;
  separator?: string;
  error?: string;
  onChipsChange?: (name: string, newChips: string[]) => void;
};

type Props = CustomProps & InputHTMLAttributes<HTMLInputElement>;

const ChipInput = ({
  id,
  icon,
  chips,
  name,
  className,
  error,
  separator,
  placeholder,
  style,
  onChipsChange,
  onKeyDown,
  ...inputProps
}: Props) => {
  const [text, setText] = useState('');
  const [_chips, setChips] = useState<string[]>([]);

  const removeChip = (index: number) => {
    const newChips = [..._chips.slice(0, index), ..._chips.slice(index + 1)];

    if (onChipsChange) onChipsChange(name || '', newChips);
    if (chips === undefined) setChips(newChips);
  };

  const pushChip = (newChip: string) => {
    const newChips = [..._chips, newChip];
    if (onChipsChange) onChipsChange(name || '', newChips);
    if (chips === undefined) setChips(newChips);
    setText('');
  };

  const handleChipClick = (index: number) => () => {
    removeChip(index);
  };

  const handleTextChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    if (
      value.endsWith(separator || ',') &&
      value.length !== (separator || ',').length
    ) {
      pushChip(value.slice(0, -1));
      return;
    }

    setText(value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) onKeyDown(event);

    if (event.key === 'Backspace' && _chips.length > 0 && text === '')
      removeChip(_chips.length - 1);
    if (event.key === 'Enter' && text !== '') pushChip(text.trim());
  };

  const renderChips = () =>
    _chips.map((chip, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={`${chip}-${index}`}>
        <Chip label={chip} onClick={handleChipClick(index)} />
        <div className={styles.chipSpacer} />
      </Fragment>
    ));

  useEffect(() => {
    if (chips !== undefined && typeof chips !== 'string') setChips(chips);
  }, [chips]);

  return (
    <label
      htmlFor={id}
      className={`${styles.container} ${className}`}
      style={style}
    >
      <div className={`${styles.wrapper} ${error ? styles.error : ''}`}>
        <p
          className={`${styles.inputTitle} ${
            _chips.length > 0 || text ? styles.withValue : ''
          }`}
        >
          {placeholder}
        </p>
        {icon ? <IonIcon name={icon} /> : null}
        <div className={styles.chipWrapper}>
          {renderChips()}
          <input
            id={id}
            className={styles.input}
            {...inputProps}
            type="text"
            value={text}
            onChange={handleTextChange}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
    </label>
  );
};

ChipInput.defaultProps = {
  icon: '',
  error: '',
  separator: ',',
  chips: undefined,
  onChipsChange: undefined
};

export default ChipInput;
