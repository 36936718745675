/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ProfileData, User } from '../types';

export interface State {
  user: User;
  profileData: ProfileData;
  missingData: {
    hasEmail: boolean;
    email?: string;
    hasPassword: boolean;
    didLoad: boolean;
  };
  didLoad: boolean;
}

const initialState: State = {
  user: {
    fullName: '',
    firstName: '',
    lastName: '',
    lastNameM: '',
    phone: '',
    active: false,
    email: '',
    status: ''
  },
  profileData: {
    email: '',
    phone: '',
    interes: 'Físico',
    name: '',
    lastName: '',
    lastNameM: '',
    curp: '',
    status: '',
    avgTicket: '',
    topTicket: '',
    webPageUrl: '',
    hostingService: '',
    turnover: '',
    deliveryMethods: [],
    otherBusiness: [],
    otherOwners: [],
    providerAccess: false,
    restrictedDirectOrders: false,
    paymentOptions: {
      card: true,
      spei: false,
      codi: false,
      cash: true
    },
    business: {
      name: '',
      address: '',
      rfc: '',
      zipCode: '',
      type: '',
      alias: ''
    },
    documentation: [],
    observations: {}
  },
  missingData: {
    hasEmail: false,
    hasPassword: false,
    didLoad: false
  },
  didLoad: false
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setProfileData(state, action) {
      state.profileData = action.payload;
    },
    setDidLoad(state, action) {
      state.didLoad = action.payload;
    },
    setStatus(state, action) {
      state.user.status = action.payload;
    },
    logout() {
      // This is handled on rootReducer.tsx
    },
    setMissingData(state, action) {
      state.missingData = action.payload;
      state.missingData.didLoad = true;
    }
  }
});

export const {
  setUser,
  setDidLoad,
  setStatus,
  setProfileData,
  logout,
  setMissingData
} = userSlice.actions;

export default userSlice.reducer;
