import React, { useState } from 'react';
import { Dictionary } from '../types';

type ReturnType = [
  Dictionary<string>,
  {
    clearError(key: string): void;
    setErrors: React.Dispatch<React.SetStateAction<Dictionary<string>>>;
  }
];

export default (): ReturnType => {
  const [errors, setErrors] = useState<Dictionary<string>>({});

  const clearError = (key: string) => {
    if (errors[key]) {
      const { [key]: removed, ...rest } = errors;
      setErrors({ ...rest });
    }
  };

  return [errors, { setErrors, clearError }];
};
