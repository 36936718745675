import { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';

import type { ChangeEvent } from 'react';

import useFormErrors from '../../hooks/useFormErrors';
import { useNotifications } from '../../providers/NotificationProvider';
import useBranches from '../../hooks/useBranches';

import styles from './CompleteModal.module.scss';

import Modal, { Props as ModalProps } from '../Modal';
import Input from '../Input';
import Select from '../Select';

import type { Branch, PaymentOptions } from '../../types';
import InlineInputGroup from '../InlineInputGroup';
import CreateBranchModal from './CreateBranchModal';

type Props = {
  id: number | string;
  paymentOptions: Partial<PaymentOptions>;
  goBack: () => void;
} & ModalProps;

interface SerialPayload {
  status: 'complete' | 'rejected';
  merchants_configuration: {
    merchant_id: number;
    is_active: boolean;
    type: 'tpv' | 'api';
  }[];
  spei_code?: number;
  codi_information?: {
    clabe: string;
    alias: number;
    certificate: number;
    checker_digit: number;
    elem_cif: string;
  };
}

const CompleteModal = ({
  id,
  paymentOptions,
  goBack,
  close,
  ...modalProps
}: Props) => {
  const [merchantId, setMerchantId] = useState('');
  const [merchantIdEcommerce, setMerchantIdEcommerce] = useState('');
  const [speiCode, setSpeiCode] = useState('');
  const [clabe, setClabe] = useState('');
  const [certificate, setCertificate] = useState('');
  const [codiAlias, setCodiAlias] = useState('');
  const [codiChecker, setCodiChecker] = useState('');
  const [elementCipher, setElementCipher] = useState('');
  const [selectedBranch, setSelectedBranch] = useState<Branch | null>(null);
  const [displayCreateBranch, setDisplayCreateBranch] = useState(false);
  const [errors, { clearError, setErrors }] = useFormErrors();

  const [branches, { updateBranch }] = useBranches(id);

  const { notify } = useNotifications();

  const handleClose = () => {
    setMerchantId('');
    setSpeiCode('');
    setClabe('');
    setCertificate('');
    setCodiAlias('');
    setElementCipher('');

    if (close) close();
  };

  const openCreateBranch = () => setDisplayCreateBranch(true);
  const closeCreateBranch = () => setDisplayCreateBranch(false);

  const forceNumericInput = (value: string) => {
    const numericRegex = /^\d+$/;
    if (!numericRegex.test(value) && value !== '')
      throw new Error('Input must be numeric.');
  };

  const forceLength = (value: string, length: number) => {
    if (value.length > length) throw new Error('Max length reached.');
  };

  const handleSelectBranch = (branchId: string) => {
    const bid = parseInt(branchId, 10);
    const index = _.findIndex(branches, ['id', bid]);
    if (index === -1) return;
    setSelectedBranch(branches[index]);
  };

  const handleTerminalIdChange = (value: string) => {
    if (!selectedBranch) return;
    const bid =
      typeof selectedBranch.id === 'string'
        ? parseInt(selectedBranch.id, 10)
        : selectedBranch.id;
    const newBranch: Branch = {
      ...selectedBranch
    };

    if (selectedBranch.terminal)
      newBranch.terminal = { ...selectedBranch.terminal, id: value };

    setSelectedBranch(newBranch);
    updateBranch(bid, newBranch);
  };

  const handleBranchCreated = (newBranch: Branch) =>
    setSelectedBranch(newBranch);

  const handleChange = ({
    target: { name, value }
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (errors[name]) clearError(name);
    try {
      switch (name) {
        case 'merchantId':
          setMerchantId(value);
          break;
        case 'merchantIdEcommerce':
          setMerchantIdEcommerce(value);
          break;
        case 'speiCode':
          forceLength(value, 3);
          forceNumericInput(value);
          setSpeiCode(value);
          break;
        case 'clabe':
          setClabe(value);
          break;
        case 'certificate':
          forceLength(value, 20);
          forceNumericInput(value);
          setCertificate(value);
          break;
        case 'codiAlais':
          forceLength(value, 20);
          forceNumericInput(value);
          setCodiAlias(value);
          break;
        case 'codiChecker':
          forceLength(value, 2);
          forceNumericInput(value);
          setCodiChecker(value);
          break;
        case 'elementCipher':
          setElementCipher(value);
          break;
        case 'branch':
          handleSelectBranch(value);
          break;
        case 'terminalId':
          handleTerminalIdChange(value);
          break;
        default:
          break;
      }
    } catch (exception) {
      console.log('Validation failed.');
    }
  };

  const validate = () => {
    const newErrors: Record<string, string> = {};

    if (!merchantId && !merchantIdEcommerce)
      newErrors.merchantId = 'Se necesita minimo un merchant ID.';

    const values = Object.values(newErrors);
    if (values.length > 0) {
      notify([
        {
          title: 'Error al validar campos.',
          message: values[0],
          type: 'alert'
        }
      ]);
      setErrors(newErrors);
      throw new Error('Error al validar campos');
    }
  };

  const handleSubmit = async () => {
    try {
      validate();
      const serialPayload: SerialPayload = {
        status: 'complete',
        merchants_configuration: []
      };

      if (merchantId)
        serialPayload.merchants_configuration.push({
          merchant_id: parseInt(merchantId, 10),
          is_active: true,
          type: 'tpv'
        });
      if (merchantIdEcommerce)
        serialPayload.merchants_configuration.push({
          merchant_id: parseInt(merchantIdEcommerce, 10),
          is_active: true,
          type: 'api'
        });

      if (paymentOptions.spei || speiCode)
        serialPayload.spei_code = parseInt(speiCode, 10);

      if (paymentOptions.codi)
        serialPayload.codi_information = {
          clabe,
          alias: parseInt(codiAlias, 10),
          certificate: parseInt(certificate, 10),
          checker_digit: parseInt(codiChecker, 10),
          elem_cif: elementCipher
        };

      const {
        data: { success, errors: resErrors }
      } = await axios.put(
        `/gateway/api/v2/administrator/business/${id}`,
        serialPayload
      );

      if (!success) {
        const error =
          resErrors.length > 0
            ? resErrors[0]
            : 'Ocurrio un error al completar este perfil.';
        throw new Error(error);
      }

      handleClose();
      goBack();
    } catch (exception) {
      console.log((exception as Error).message);
    }
  };

  const renderBranchOptions = () =>
    branches.map(branch => (
      <option key={branch.id} value={branch.id}>
        {branch.name}
      </option>
    ));

  useEffect(() => {
    if (selectedBranch === null && branches.length > 0)
      setSelectedBranch(branches[0]);
  }, [branches, selectedBranch]);

  return (
    <>
      <Modal {...modalProps} close={handleClose}>
        <div className={styles.container}>
          <p className={styles.title}>Completar perfil</p>
          <Input
            id="merchantId"
            name="merchantId"
            placeholder="Merchant ID"
            value={merchantId}
            error={errors.merchantId}
            onChange={handleChange}
          />
          <div className={styles.spacer} />
          <Input
            id="merchantIdEcommerce"
            name="merchantIdEcommerce"
            placeholder="Merchant ID Ecommerce"
            value={merchantIdEcommerce}
            error={errors.merchantIdEcommerce}
            onChange={handleChange}
          />
          {paymentOptions.card ? (
            <>
              <div className={styles.divider} />
              <div className={styles.titleWrapper}>
                <p className={styles.sectionTitle}>Tarjeta</p>
                <button
                  type="button"
                  className={styles.createBranchButton}
                  onClick={openCreateBranch}
                >
                  Crear sucursal
                </button>
              </div>
              <InlineInputGroup basis="50%" spacing={8}>
                <Select
                  id="branchSelect"
                  placeholder="Sucursal"
                  name="branch"
                  defaultValue={selectedBranch ? selectedBranch.id : ''}
                  value={selectedBranch ? selectedBranch.id : ''}
                  onChange={handleChange}
                >
                  {renderBranchOptions()}
                </Select>
                <Input
                  id="terminalId"
                  name="terminalId"
                  placeholder="Terminal ID"
                  value={selectedBranch ? selectedBranch.terminal?.id : ''}
                  onChange={handleChange}
                />
              </InlineInputGroup>
            </>
          ) : null}
          {paymentOptions.spei ? (
            <>
              <div className={styles.divider} />
              <p className={styles.sectionTitle}>SPEI</p>
              <Input
                id="speiCode"
                name="speiCode"
                type="number"
                placeholder="Centro de costos"
                maxLength={3}
                value={speiCode}
                error={errors.speiCode}
                onChange={handleChange}
              />
            </>
          ) : null}
          {paymentOptions.codi ? (
            <>
              <div className={styles.divider} />
              <p className={styles.sectionTitle}>CoDi</p>
              <Input
                id="clabe"
                name="clabe"
                placeholder="CLABE"
                value={clabe}
                error={errors.clabe}
                onChange={handleChange}
              />
              <div className={styles.spacer} />
              <Input
                id="certificate"
                name="certificate"
                type="number"
                placeholder="Certificado"
                maxLength={20}
                value={certificate}
                error={errors.certificate}
                onChange={handleChange}
              />
              <div className={styles.spacer} />
              <Input
                id="codiAlias"
                name="codiAlias"
                type="number"
                placeholder="Alias"
                maxLength={20}
                value={codiAlias}
                error={errors.codiAlias}
                onChange={handleChange}
              />
              <div className={styles.spacer} />
              <Input
                id="codiChecker"
                name="codiChecker"
                type="number"
                placeholder="Digito verificador"
                maxLength={2}
                value={codiChecker}
                error={errors.codiChecker}
                onChange={handleChange}
              />
              <div className={styles.spacer} />
              <Input
                id="elementCipher"
                name="elementCipher"
                placeholder="Elemento cifrado"
                value={elementCipher}
                error={errors.elementCipher}
                onChange={handleChange}
              />
            </>
          ) : null}
        </div>
        <div className={styles.footer}>
          <button
            className={styles.tertiaryButton}
            type="button"
            onClick={handleClose}
          >
            Cancelar
          </button>
          <button
            className={styles.primaryButton}
            type="button"
            onClick={handleSubmit}
          >
            Completar
          </button>
        </div>
      </Modal>
      <CreateBranchModal
        businessId={id}
        visible={displayCreateBranch}
        onBranchCreated={handleBranchCreated}
        close={closeCreateBranch}
      />
    </>
  );
};

export default CompleteModal;
