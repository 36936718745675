import { useState } from 'react';
import _ from 'lodash';

import type { ChangeEvent } from 'react';

import useBranches from '../../hooks/useBranches';
import useFormErrors from '../../hooks/useFormErrors';
import { useNotifications } from '../../providers/NotificationProvider';

import styles from './CreateBranchModal.module.scss';

import Modal, { Props as ModalProps } from '../Modal';
import Input from '../Input';
import InlineInputGroup from '../InlineInputGroup';

import type { Branch, BranchPayload, Zone, ZonePayload } from '../../types';
import useZones from '../../hooks/useZones';
import ZoneSelect from './ZoneSelect';

type Props = {
  businessId: number | string;
  onBranchCreated?: (newBranch: Branch) => void;
} & ModalProps;

const CreateBranchModal = ({
  businessId,
  onBranchCreated,
  close,
  ...modalProps
}: Props) => {
  const [branchName, setBranchName] = useState('');
  const [shortName, setShortName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [terminalId, setTerminalId] = useState('');
  const [selectedZone, setSelectedZone] = useState<Zone | null>(null);
  const [errors, { clearError }] = useFormErrors();
  const [, { createBranch }] = useBranches(businessId, { autoLoad: false });

  const [zones, { createZone }] = useZones(businessId);

  const { notify } = useNotifications();

  const handleClose = () => {
    setBranchName('');
    setShortName('');
    setEmail('');
    setPhone('');
    setAddress('');
    setTerminalId('');

    if (close) close();
  };

  const handleChange = ({
    target: { name, value }
  }: ChangeEvent<HTMLInputElement>) => {
    clearError(name);
    switch (name) {
      case 'name':
        setBranchName(value);
        break;
      case 'shortName':
        setShortName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      case 'address':
        setAddress(value);
        break;
      default:
        break;
    }
  };

  const generateCode = () => Math.random().toString(36).slice(2);

  const handleZoneChange = (value: string | null) => {
    if (value === null) {
      setSelectedZone(null);
      return;
    }

    const id = parseInt(value, 10);
    const index = _.findIndex(zones, ['id', id]);

    if (index === -1) return;

    setSelectedZone(zones[index]);
  };

  const handleCreateZone = async (zoneName: string) => {
    try {
      const payload: ZonePayload = {
        name: zoneName,
        zone_code: generateCode()
      };

      const zone = await createZone(payload);
      setSelectedZone(zone);
    } catch (exception) {
      notify([
        {
          title: 'Error al crear zona',
          message: 'Ocurrio un error al crear la zona.',
          type: 'alert'
        }
      ]);
    }
  };

  const handleSubmit = async () => {
    try {
      const payload: BranchPayload = {
        name: branchName,
        zone_id: 515,
        branch_code: generateCode(),
        short_name: shortName,
        email,
        phone,
        address,
        terminal_id: terminalId ? parseInt(terminalId, 10) : null
      };

      const newBranch = await createBranch(payload);

      if (onBranchCreated) onBranchCreated(newBranch);
      handleClose();
    } catch (exception) {
      console.log((exception as Error).message);
      notify([
        {
          title: 'Error al crear sucursal',
          message: 'Ocurrio un error al crear la sucursal.',
          type: 'alert'
        }
      ]);
    }
  };

  const renderZoneOptions = () =>
    zones.map(zone => (
      <option key={zone.id} value={zone.id}>
        {zone.name}
      </option>
    ));

  return (
    <Modal {...modalProps} close={handleClose}>
      <div className={styles.container}>
        <p className={styles.title}>Crear sucursal</p>
        <InlineInputGroup basis="50%" spacing={8}>
          <Input
            id="name"
            name="name"
            placeholder="Nombre"
            value={branchName}
            error={errors.name}
            onChange={handleChange}
          />
          <Input
            id="shortName"
            name="shortName"
            placeholder="Alias"
            value={shortName}
            error={errors.shortName}
            onChange={handleChange}
          />
        </InlineInputGroup>
        <div className={styles.spacer} />
        <ZoneSelect
          id="zoneSelectInput"
          label="Seleccionar zona"
          actionText="Crear zona"
          applyActionText="Crear zona"
          selectedValue={selectedZone ? `${selectedZone.id}` : undefined}
          inputProps={{ placeholder: 'Nombre de la zona' }}
          onChange={handleZoneChange}
          onApply={handleCreateZone}
        >
          {renderZoneOptions()}
        </ZoneSelect>
        <div className={styles.spacer} />
        <p className={styles.sectionTitle}>Contacto</p>
        <InlineInputGroup basis="50%" spacing={8}>
          <Input
            id="email"
            name="email"
            placeholder="Email"
            value={email}
            error={errors.email}
            onChange={handleChange}
          />
          <Input
            id="phone"
            name="phone"
            placeholder="Teléfono"
            value={phone}
            error={errors.phone}
            onChange={handleChange}
          />
        </InlineInputGroup>
        <div className={styles.spacer} />
        <Input
          id="address"
          name="address"
          placeholder="Dirección"
          value={address}
          error={errors.phone}
          onChange={handleChange}
        />
      </div>
      <div className={styles.footer}>
        <button
          className={styles.tertiaryButton}
          type="button"
          onClick={handleClose}
        >
          Cancelar
        </button>
        <button
          className={styles.primaryButton}
          type="button"
          onClick={handleSubmit}
        >
          Crear
        </button>
      </div>
    </Modal>
  );
};

CreateBranchModal.defaultProps = {
  onBranchCreated: undefined
};

export default CreateBranchModal;
