import styles from './index.module.scss';

import { Product, Quantities } from '../../types';

import ProductRow from './ProductoRow';

type Props = {
  products: Product[];
  quantities: Quantities;
  handleQuantityChange: (id: string) => (newValue: number) => void;
};

const Store = ({ products, quantities, handleQuantityChange }: Props) => {
  const renderProducts = () =>
    products.map((product, index) => (
      <ProductRow
        key={product.id}
        backgroundColor={
          (index + 1) % 2 === 0 ? 'rgba(255, 255, 255, 0.4)' : 'transparent'
        }
        image={product.image}
        name={product.name}
        price={product.price}
        type="type"
        // @ts-ignore
        quantity={quantities[product.id]}
        onQuantityChange={handleQuantityChange(product.id)}
      />
    ));

  return <div className={styles.container}>{renderProducts()}</div>;
};

export default Store;
