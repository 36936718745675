import axios from 'axios';

import { setDidLoad, setProfileData, setUser } from '../slices/user';

import TokenService from './TokenService';
import store from '../store';

export default class AuthService {
  static async fetchUser() {
    const {
      data: { user, profileData }
    } = await axios.get('/paycode/user');

    store.dispatch(setUser(user));
    store.dispatch(setProfileData(profileData));
    store.dispatch(setDidLoad(true));
  }

  static async login(email: string, password: string, userType = 'user') {
    const endpoint =
      userType === 'admin' ? '/paycode/admin/login' : '/paycode/login';
    const {
      data: { token }
    } = await axios.post(endpoint, { email, password });

    TokenService.set(token, userType === 'admin');

    if (userType !== 'admin') await this.fetchUser();
  }

  static async signup(body: { email: string; password: string }) {
    const {
      data: { token }
    } = await axios.post('paycode/signup', body);
    TokenService.set(token);

    await this.fetchUser();

    return token;
  }
}
