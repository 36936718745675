/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Dictionary, TransferItem } from '../types';

export interface State {
  transfers: Dictionary<TransferItem>;
  queue: string[];
  activeTransferCount: number;
}

const initialState: State = {
  transfers: {},
  queue: [],
  activeTransferCount: 0
};

const queueSlice = createSlice({
  name: 'queueSlice',
  initialState,
  reducers: {
    setTransfer(state, action) {
      const { id, transfer } = action.payload;
      state.transfers[id] = transfer;
    },
    setTransferProperty(state, action) {
      const { id, property, value } = action.payload;
      // @ts-ignore
      state.transfers[id][property] = value;
    },
    pushToQueue(state, action) {
      state.queue.push(action.payload);
    },
    shiftQueue(state) {
      state.queue.shift();
    },
    setActiveTransferCount(state, action) {
      state.activeTransferCount = action.payload;
    }
  }
});

export const {
  setTransfer,
  pushToQueue,
  shiftQueue,
  setActiveTransferCount,
  setTransferProperty
} = queueSlice.actions;

export default queueSlice.reducer;
