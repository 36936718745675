import styles from './index.module.scss';
import Input from '../../Input';
import Modal from '../../Modal';

type Props = {
  visible: boolean;
  close(): void;
};

const PopupSellerReference = ({ close, visible }: Props) => (
  <Modal close={close} visible={visible}>
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <p className={styles.header}>Referencia del vendedor</p>
        <p className={styles.text}>
          Introduce la referencia que te dio tu vendedor aquí para que se
          respete la tasa especial en tu cuenta
        </p>
        <Input
          id="sellerReference"
          placeholder="Referencia"
          name="reference"
          className={styles.input}
        />
      </div>
      <div className={styles.divider} />
      <div className={styles.footer}>
        <div className={styles.buttons}>
          <button type="button" className={styles.button} onClick={close}>
            Cancelar
          </button>
          <button
            type="button"
            className={styles.button}
            style={{ color: '#55D696' }}
          >
            Aplicar
          </button>
        </div>
      </div>
    </div>
  </Modal>
);

export default PopupSellerReference;
