import { useState, useEffect, useRef } from 'react';

import IonIcon from '@reacticons/ionicons';

import styles from './InfoBubble.module.scss';

type Props = {
  text: string;
  style?: object;
};

const InfoBubble = ({ text, style }: Props) => {
  const [active, setActive] = useState(false);
  const [bubbleStyles, setBubbleStyles] = useState({});
  const [triangleStyles, setTriangleStyles] = useState({});
  const [trianglePosition, setTrianglePosition] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const mouseEnter = () => setActive(true);
  const mouseLeave = () => setActive(false);

  useEffect(() => {
    const handleRisize = () => {
      const _elemRect = containerRef.current?.getBoundingClientRect();
      const offsetRight = _elemRect?.right;

      if (_elemRect && offsetRight) {
        const diference = window.innerWidth - offsetRight;

        setBubbleStyles({
          ...bubbleStyles,
          right: -diference + 8
        });

        setTriangleStyles({ ...triangleStyles, right: diference - 8 });
      }
    };

    const handleScroll = () => {
      const elemRect = containerRef.current?.getBoundingClientRect();
      const offsetBottom = elemRect?.bottom;

      if (elemRect && offsetBottom) {
        if (offsetBottom <= 128) {
          setBubbleStyles({ ...bubbleStyles, bottom: -72 });
          setTriangleStyles({ ...triangleStyles, top: -8 });
          setTrianglePosition(true);
        } else {
          setBubbleStyles({ ...bubbleStyles, bottom: 32 });
          setTriangleStyles({ ...triangleStyles, bottom: -8 });
          setTrianglePosition(false);
        }
      }
    };

    setTimeout(() => handleRisize(), 25);

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleRisize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleRisize);
    };
  }, []);

  return (
    <div className={styles.iconWrapper} ref={containerRef} style={style}>
      <IonIcon
        name="help"
        className={styles.icon}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      />
      {active ? (
        <div className={styles.bubble} style={bubbleStyles}>
          <p>{text}</p>
          <div
            className={`${styles.triangle} ${
              trianglePosition ? styles.top : ''
            }`}
            style={triangleStyles}
          />
        </div>
      ) : null}
    </div>
  );
};

InfoBubble.defaultProps = {
  style: {}
};

export default InfoBubble;
