import { ButtonHTMLAttributes, ReactNode, useMemo } from 'react';
import IonIcon from '@reacticons/ionicons';
import styles from './ActionButton.module.scss';

type CustomProps = {
  preset: 'primary' | 'secondary' | 'tertiary' | 'none' | 'secondary-black';
  badge?: number;
  text?: string;
  icon?: string;
  children?: ReactNode | ReactNode[];
};

type Props = CustomProps & ButtonHTMLAttributes<HTMLButtonElement>;

const ActionButton = ({
  preset,
  className,
  children,
  text,
  icon,
  badge,
  ...buttonProps
}: Props) => {
  const presetClassName = useMemo(() => {
    if (preset === 'primary') return styles.primary;
    if (preset === 'secondary') return styles.secondary;
    if (preset === 'secondary-black') return styles.secondaryBlack;
    if (preset === 'tertiary') return styles.tertiary;
    return '';
  }, [preset]);

  return (
    <button
      type="button"
      {...buttonProps}
      className={`${styles.button} ${presetClassName} ${className}`}
    >
      {badge ? <div className={styles.badge}>{badge}</div> : null}
      {icon ? (
        <IonIcon
          className={`${styles.icon} ${presetClassName}`}
          name={icon as any}
        />
      ) : null}
      {text ? (
        <p className={`${styles.text} ${presetClassName}`}>{text}</p>
      ) : null}
      {children}
    </button>
  );
};

ActionButton.defaultProps = {
  badge: 0,
  text: '',
  icon: undefined,
  children: null
};

export default ActionButton;
