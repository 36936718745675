import { useState } from 'react';
import IonIcon from '@reacticons/ionicons';

import type { InputHTMLAttributes } from 'react';

import styles from './Input.module.scss';

import InfoBubble from './InfoBubble';

type CustomProps = {
  showVisibilityToggle?: boolean;
  icon?: any;
  error?: string;
  helpDescription?: string;
};

type Props = CustomProps & InputHTMLAttributes<HTMLInputElement>;

const Input = ({
  id,
  icon,
  showVisibilityToggle,
  value,
  className,
  style,
  error,
  type: propsType,
  placeholder,
  helpDescription,
  ...inputProps
}: Props) => {
  const [type, setType] = useState(propsType || 'text');

  const toggleVisibility = () =>
    setType(type === 'password' ? 'text' : 'password');

  return (
    <label
      htmlFor={id}
      className={`${styles.container} ${className}`}
      style={style}
    >
      <div className={`${styles.wrapper} ${error ? styles.error : ''}`}>
        <p className={`${styles.inputTitle} ${value ? styles.withValue : ''}`}>
          {placeholder}
        </p>
        {icon ? <IonIcon name={icon} className={styles.icon} /> : null}
        {helpDescription !== '' ? (
          <InfoBubble
            text={helpDescription || ''}
            style={{ position: 'absolute', left: 208, top: 18 }}
          />
        ) : null}
        <input
          id={id}
          type={type}
          value={value}
          className={styles.input}
          {...inputProps}
        />
        {propsType === 'password' && showVisibilityToggle ? (
          <button
            type="button"
            className={styles.visibilityButton}
            onClick={toggleVisibility}
          >
            {type === 'password' ? (
              <IonIcon name="eye" />
            ) : (
              <IonIcon name="eye-off" />
            )}
          </button>
        ) : null}
      </div>
    </label>
  );
};

Input.defaultProps = {
  showVisibilityToggle: false,
  icon: '',
  error: '',
  helpDescription: ''
};

export default Input;
