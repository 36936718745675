import styles from './ProductRow.module.scss';

type Props = {
  isLast?: boolean;
  count?: number;
  name?: string;
  price: number;
  img: string;
};

const ProductRow = ({ isLast, count, name, price, img }: Props) => (
  <div className={styles.container}>
    <div className={styles.imgWrapper}>
      <img alt="" src={img} className={styles.img} />
      <div className={styles.counter}>
        <p className={styles.count}>{count}</p>
      </div>
    </div>
    <div className={styles.textWrapper}>
      <p className={styles.name}>{name}</p>
      <div className={styles.priceWrapper}>
        <p className={styles.price}>
          {`$${price ? price.toLocaleString() : ''}`}
        </p>
        <p className={styles.currency}>MXN</p>
      </div>
    </div>
    <div className={isLast ? '' : styles.divider} />
  </div>
);

ProductRow.defaultProps = {
  isLast: false,
  count: 0,
  name: ''
};

export default ProductRow;
