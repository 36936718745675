import styles from './index.module.scss';

import ProfileRow from './ProfileRow';
import Header from './Header';
import Footer from './Footer';

import { Profile } from '../../../types';

type Props = {
  profiles: Profile[];
  page: number;
  totalPages: number;
  setPage(newPage: number): void;
  onProfileClick: (id: number) => () => void;
};

const Profiles = ({
  profiles,
  page,
  totalPages,
  setPage,
  onProfileClick
}: Props) => {
  const renderProfiles = () =>
    profiles.map(profile => (
      <ProfileRow
        key={profile.id}
        email={profile.email}
        businessName={profile.businessName}
        status={profile.status}
        onClick={onProfileClick(profile.id)}
      />
    ));

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.list}>{renderProfiles()}</div>
      <Footer currentPage={page} totalPages={totalPages} setPage={setPage} />
    </div>
  );
};

export default Profiles;
