import { Fragment, useEffect, useState } from 'react';

import styles from './index.module.scss';

import Chip from './TextChip';

type Props = {
  chips: string[];
  name?: string;
  value?: string;
  onChange?: (name: string, newValue: string) => void;
};

const TextChipSelect = ({ chips, name, value, onChange }: Props) => {
  const [_value, setValue] = useState('');

  const handleClick = (selectedValue: string) => () => {
    if (onChange) onChange(name || '', selectedValue);
    if (value === undefined) setValue(selectedValue);
  };

  const handleInitialValue = () => {
    if (chips.length === 0) return;
    setValue(chips[0]);
  };

  const renderChips = () =>
    chips.map((chip, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={`${chip}-${index}`}>
        <Chip
          text={chip}
          selected={_value === chip}
          onClick={handleClick(chip)}
        />
        {index !== chips.length - 1 ? (
          <div className={styles.chipSpacer} />
        ) : null}
      </Fragment>
    ));

  useEffect(() => {
    handleInitialValue();
  }, []);

  useEffect(() => {
    if (value !== undefined) setValue(value);
  }, [value]);

  return <div className={styles.container}>{renderChips()}</div>;
};

TextChipSelect.defaultProps = {
  value: undefined,
  onChange: undefined,
  name: ''
};

export default TextChipSelect;
