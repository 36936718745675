import styles from './Header.module.scss';

const Header = () => (
  <div className={styles.row}>
    <div className={styles.left}>
      <p className={styles.business}>Nombre del negocio</p>
      <p className={styles.name}>Email</p>
    </div>
    <div className={styles.right}>
      <p className={styles.status}>Status</p>
    </div>
  </div>
);

export default Header;
