import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';

import { Product, Quantities } from '../types';

import imgWisePos from '../assets/images/wisePos.png';
import imgWisePad from '../assets/images/wisePad-2.png';
import imgTarjeta from '../assets/images/card.png';

type Props = {
  children: ReactNode | ReactNode[];
};

type ContextData = {
  products: Product[];
  quantities: Quantities;
  setQuantity(id: string, value: number): void;
};

const initialQuantities: Quantities = {
  wisepos: 0,
  wisepad: 0,
  nfcCard: 0
};

const products: Product[] = [
  {
    id: 'wisepos',
    name: 'WisePos E Plus',
    price: 4000,
    image: imgWisePos,
    type: 'Lector de tarjetas'
  },
  {
    id: 'wisepad',
    name: 'WisePad 2 Version Q',
    price: 4000,
    image: imgWisePad,
    type: 'Lector de tarjetas'
  },
  {
    id: 'nfcCard',
    name: 'Tarjeta Links de Pago',
    price: 4000,
    image: imgTarjeta,
    type: 'Tarjeta de cobro NFC'
  }
];

export const CreateStoreContext = createContext<ContextData>({
  products,
  quantities: initialQuantities,
  setQuantity: () => {}
});

export const CreateStoreProvider = ({ children }: Props) => {
  const [quantities, setQuantities] = useState(initialQuantities);

  const setQuantity = useCallback(
    (id: string, value: number) => {
      setQuantities({ ...quantities, [id]: value });
    },
    [quantities]
  );

  const contextValue = useMemo(
    () => ({ products, quantities, setQuantity }),
    [quantities]
  );

  return (
    <CreateStoreContext.Provider value={contextValue}>
      {children}
    </CreateStoreContext.Provider>
  );
};

export const useStoreData = () => useContext(CreateStoreContext);
