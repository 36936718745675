import { useState } from 'react';
import { useNavigate } from 'react-router';

import type { ChangeEvent } from 'react';

import useFormErrors from '../../hooks/useFormErrors';
import { useNotifications } from '../../providers/NotificationProvider';
import AuthService from '../../lib/AuthService';

import AdminAuth from '../../components/Admin/Auth';

const AdminAuthContainer = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authorizing, setAuthorizing] = useState(false);
  const [errors, { clearError, setErrors }] = useFormErrors();

  const navigate = useNavigate();

  const { notify } = useNotifications();

  const handleTextChange = ({
    target: { name, value }
  }: ChangeEvent<HTMLInputElement>) => {
    clearError(name);
    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const validate = () => {
    const newErrors: Record<string, string> = {};

    if (!email) newErrors.email = 'Email es obligatorio.';
    if (!password) newErrors.password = 'Password es obligatorio.';

    const values = Object.values(newErrors);
    if (values.length > 0) {
      notify([
        {
          title: 'Error al validar campos.',
          message: values[0],
          type: 'alert'
        }
      ]);
      setErrors(newErrors);
      throw new Error('Error al validar campos');
    }
  };

  const authorize = async () => {
    setAuthorizing(true);
    try {
      validate();

      await AuthService.login(email, password, 'admin');

      // Check roles and handle nav accordingly
      navigate('/admin/profiles');
    } catch (exception) {
      console.log((exception as Error).message);
    }
    setAuthorizing(false);
  };

  return (
    <AdminAuth
      email={email}
      password={password}
      authorizing={authorizing}
      errors={errors}
      handleTextChange={handleTextChange}
      authorize={authorize}
    />
  );
};

export default AdminAuthContainer;
