import { createContext, useContext, useMemo, useRef, useState } from 'react';
import _ from 'lodash';

import type { ReactNode } from 'react';

import Notification from '../components/Notification';

import { Notification as NotificationType } from '../types';

type Props = {
  children: ReactNode | ReactNode[];
};

type ContextData = {
  notify(notifications: NotificationType[]): void;
};

export const NotificationContext = createContext<ContextData>({
  notify: () => {}
});

export const NotificationProvider = ({ children }: Props) => {
  const [queue, _setQueue] = useState<NotificationType[]>([]);

  const queueRef = useRef(queue);

  const setQueue = (newValue: NotificationType[]) => {
    _setQueue(newValue);
    queueRef.current = newValue;
  };

  const handleDismiss = (id: string) => () => {
    const index = _.findIndex(queueRef.current, ['id', id]);
    if (queueRef.current.length === 0 || index === -1) return;

    setQueue([
      ...queueRef.current.slice(0, index),
      ...queueRef.current.slice(index + 1)
    ]);
  };

  const notify = (notifications: NotificationType[]) => {
    const withIds = notifications.map(notification => {
      const id = Math.random().toString().slice(2, 12);
      return { ...notification, id };
    });

    setQueue([...queueRef.current, ...withIds]);
  };

  const renderNotifications = () =>
    queue.map(({ message, type, title, id }, index) => (
      <Notification
        key={id}
        message={message}
        type={type}
        title={title}
        index={index}
        dismiss={handleDismiss(id || '')}
      />
    ));

  const value = useMemo(() => ({ notify }), []);

  return (
    <NotificationContext.Provider value={value}>
      {queue.length > 0 ? (
        <div
          style={{
            position: 'fixed',
            right: 0,
            padding: 16,
            top: 0,
            zIndex: 1000
          }}
        >
          {renderNotifications()}
        </div>
      ) : null}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationContext);
