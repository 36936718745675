import { useNavigate } from 'react-router-dom';
import IonIcon from '@reacticons/ionicons';

import styles from './OptionRow.module.scss';

type Props = {
  text: string;
  count: number;
  maxCount: number;
  hrefId: string;
  active: boolean;
};

const OptionRow = ({ text, count, maxCount, hrefId, active }: Props) => {
  const done = count === maxCount;
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/dashboard#${hrefId}`);
  };

  return (
    <button
      type="button"
      className={`${styles.container} ${active ? styles.active : ''}`}
      onClick={handleOnClick}
    >
      <div className={`${styles.indicator} ${active ? styles.active : ''}`} />
      <div className={styles.wrapper}>
        <p className={styles.text}>{text}</p>
        <div className={styles.right}>
          <p className={styles.completeCounter}>
            {count}/{maxCount}
          </p>
          <div className={styles.iconWrapper}>
            {done ? <IonIcon name="checkmark" /> : null}
          </div>
        </div>
      </div>
    </button>
  );
};

export default OptionRow;
