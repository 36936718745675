import { Provider } from 'react-redux';
import { NotificationProvider } from '../providers/NotificationProvider';

import Routes from '../routes';
import store from '../store';
import { PopupProvider } from '../providers/PopupProvider';

const Root = () => (
  <Provider store={store}>
    <NotificationProvider>
      <PopupProvider>
        <Routes />
      </PopupProvider>
    </NotificationProvider>
  </Provider>
);

export default Root;
