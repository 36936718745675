import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import TokenService from '../lib/TokenService';
import Auth from '../lib/AuthService';
import { useDispatch, useSelector } from './useRedux';
import { logout, setMissingData } from '../slices/user';

const useMissingData = (prefillToken: string | null) => {
  const didLoad = useSelector(state => state.user.missingData.didLoad);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchMissingData = async () => {
    try {
      const {
        data: { email, hasPassword, token: authToken }
      } = await axios.get('/onboarding/missingData', {
        headers: { Authorization: `Bearer ${prefillToken}` }
      });

      if (authToken) {
        if (TokenService.get()) dispatch(logout());

        TokenService.set(authToken);
        await Auth.fetchUser();
        navigate('/dashboard', { replace: true });
        return;
      }

      dispatch(setMissingData({ email, hasPassword, hasEmail: !!email }));
    } catch (exception) {
      console.log((exception as Error).message);
    }
  };

  useEffect(() => {
    if (prefillToken && !didLoad) fetchMissingData();
  }, []);
};

export default useMissingData;
