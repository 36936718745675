import IonIcon from '@reacticons/ionicons';

import styles from './PaymentMethodItem.module.scss';

type Props = {
  iconName: 'card' | 'cash' | 'business' | 'qr-code';
  method: 'card' | 'cash' | 'spei' | 'codi';
};

const PaymentMethodItem = ({ iconName, method }: Props) => {
  const handleText = () => {
    switch (method) {
      case 'card':
        return 'Tarjeta';
      case 'cash':
        return 'Effectivo';
      case 'spei':
        return 'SPEI';
      case 'codi':
        return 'CoDi';
      default:
        return '';
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.iconWrapper}>
        <IonIcon name={iconName} className={styles.icon} />
      </div>
      <p className={styles.text}>{handleText()}</p>
    </div>
  );
};

export default PaymentMethodItem;
