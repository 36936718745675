import { ChangeEvent } from 'react';
import styles from './InputSegment.module.scss';

import Input from '../Input';
import InlineInputGroup from '../InlineInputGroup';

import { FormData } from '../../types';

type Props = {
  type: string;
  data: FormData;
  onTextChange(event: ChangeEvent<HTMLInputElement>): void;
};

const InputSegment = ({ onTextChange, type, data }: Props) => (
  <div className={styles.container}>
    <Input
      id={`${type}-choNameInput`}
      placeholder="Nombre Completo"
      name={`${type}/fullName`}
      onChange={onTextChange}
      value={data.fullName}
    />
    <div className={styles.inputSpacer} />
    <InlineInputGroup basis={['70%', '30%']}>
      <Input
        id={`${type}-choAddressInput`}
        placeholder="Dirección"
        name={`${type}/address`}
        onChange={onTextChange}
        value={data.address}
      />
      <Input
        id={`${type}-choZipCodeInput`}
        placeholder="Codigo Postal"
        name={`${type}/zipCode`}
        onChange={onTextChange}
        value={data.zipCode}
      />
    </InlineInputGroup>
    <div className={styles.inputSpacer} />
    <InlineInputGroup basis="50%">
      <Input
        id={`${type}-choCityInput`}
        placeholder="Ciudad"
        name={`${type}/city`}
        onChange={onTextChange}
        value={data.city}
      />
      <Input
        id={`${type}-choSublocalityInput`}
        placeholder="Colonia"
        name={`${type}/sublocality`}
        onChange={onTextChange}
        value={data.sublocality}
      />
    </InlineInputGroup>
    <div className={styles.inputSpacer} />
    <Input
      id={`${type}-choPhone`}
      placeholder="Teléfono"
      name={`${type}/phone`}
      value={data.phone}
    />
  </div>
);

export default InputSegment;
