import { useLocation, useNavigate } from 'react-router-dom';

import styles from './SideBar.module.scss';

import NavButton from '../SideBar/NavButton';

type Props = {
  short?: boolean;
};

const SideBar = ({ short }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleNavigate = (to: string) => () => navigate(to);

  return (
    <div className={`${styles.container} ${short ? styles.short : ''}`}>
      <NavButton
        text="Perfiles"
        iconName={pathname === '/admin/profiles' ? 'people' : 'people-outline'}
        active={pathname === '/admin/profiles'}
        onClick={handleNavigate('/admin/profiles')}
        short={short}
      />
    </div>
  );
};

SideBar.defaultProps = {
  short: false
};

export default SideBar;
