import { useLayoutEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import useCompareDeviceWidth from '../hooks/useCompareDeviceWidth';

import { ProfileDataProvider } from '../providers/ProfileDataProvider';
import { CreateStoreProvider } from '../providers/CreateStoreProvider';

import Onboarding from './Onboarding';
import SideBar from '../components/SideBar';
import Nav from '../components/Nav';
import Store from './Store';
import Checkout from './CheckoutScreen';

const DashboardRouter = () => {
  const location = useLocation();
  const [compare] = useCompareDeviceWidth();
  const [displaySidebar, setDisplaySidebar] = useState(compare('>tablet'));

  const toggleSidebar = () => setDisplaySidebar(!displaySidebar);

  useLayoutEffect(() => {
    const href = location.hash.split('#')[1];
    const element = document.getElementById(href);
    let timeout: any;

    if (location.pathname !== '/dashboard') window.scrollTo(0, 0);
    if (location.hash && element) {
      timeout = setTimeout(() => {
        const offset = element.offsetTop;
        window.scrollTo({ top: offset - 56, left: 0, behavior: 'smooth' });
      }, 25);
    }

    return () => {
      if (timeout) window.clearTimeout(timeout);
    };
  }, [location]);

  return (
    <ProfileDataProvider>
      <SideBar display={displaySidebar} toggleSidebar={toggleSidebar} />
      <Nav toggleSidebar={toggleSidebar} paddingLeft />
      <CreateStoreProvider>
        <Routes>
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/cart" element={<Store />} />
          <Route path="/" element={<Onboarding />} />
        </Routes>
      </CreateStoreProvider>
    </ProfileDataProvider>
  );
};

export default DashboardRouter;
