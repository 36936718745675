import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useSelector } from '../hooks/useRedux';
import TokenService from '../lib/TokenService';

type Props = {
  admin?: boolean;
  children: ReactNode | ReactNode[];
};

const ProtectedRoute = ({ admin, children }: Props): any => {
  const didLoad = useSelector(state => state.user.didLoad);
  const location = useLocation();

  const token = TokenService.get(admin);

  if (admin && !token) return <Navigate to="/admin/login" replace />;

  if (admin && token) return children;

  if (!didLoad && !token)
    return <Navigate to="/" state={{ from: location }} replace />;

  if (!didLoad && token)
    return <Navigate to="/splash" state={{ from: location }} replace />;

  return children;
};

ProtectedRoute.defaultProps = {
  admin: false
};

export default ProtectedRoute;
