import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const TOKEN_KEY = 'AUTH_TOKEN';
const ADMIN_TOKEN_KEY = 'ADMIN_AUTH_TOKEN';

export default class TokenService {
  static onRemove: () => void;

  static get(admin = false): string | null {
    const key = admin ? ADMIN_TOKEN_KEY : TOKEN_KEY;
    return cookies.get(key) || null;
  }

  static set(newToken: string, admin = false) {
    const key = admin ? ADMIN_TOKEN_KEY : TOKEN_KEY;
    cookies.set(key, newToken, { path: '/' });
    axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
  }

  static remove(admin = false) {
    const key = admin ? ADMIN_TOKEN_KEY : TOKEN_KEY;
    cookies.remove(key);
    if (this.onRemove) this.onRemove();
  }

  static async refreshToken(admin = false) {
    try {
      const {
        data: { token }
      } = await axios.get('/paycode/refreshToken');

      this.set(token, admin);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;

      return token;
    } catch (exception) {
      this.remove(admin);
      console.log((exception as Error).message);
    }
  }
}
