import { useEffect, useState } from 'react';
import type { MutableRefObject } from 'react';

type Options = {
  numberOfSegments: number;
  substractFromTotal?: number;
  substractFromWidth?: number;
  containerRef: MutableRefObject<HTMLDivElement | null>;
};

export default ({
  numberOfSegments,
  substractFromTotal,
  substractFromWidth,
  containerRef
}: Options) => {
  const [segmentWidth, setSegmentWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    if (containerWidth !== 0) {
      setSegmentWidth(
        (containerWidth - (substractFromWidth || 0)) / numberOfSegments -
          (substractFromTotal || 0)
      );
    }
  }, [numberOfSegments, containerWidth]);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, [containerRef]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current)
        setContainerWidth(containerRef.current.offsetWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return segmentWidth;
};
