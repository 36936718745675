import IonIcon from '@reacticons/ionicons';

import styles from './ProcessingModal.module.scss';
import Modal, { Props as ModalProps } from '../Modal';

const ProcessingModal = (props: ModalProps) => (
  <Modal {...props}>
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.iconWrapper}>
          <IonIcon name="reader" className={styles.icon} />
        </div>
        <p className={styles.title}>¡Cuenta en revision!</p>
        <p className={styles.description}>
          Estamos revisando su infromación, una vez que acabe este proceso le
          informaremos si su cuenta ha sido completada o si necesita realizar
          cambios a su información.
        </p>
      </div>
    </div>
  </Modal>
);

export default ProcessingModal;
