import { useEffect, useMemo, useState } from 'react';
import IonIcon from '@reacticons/ionicons';

import styles from './Notification.module.scss';

import { NotificationType } from '../types';

type Props = {
  message: string;
  type: NotificationType;
  title?: string;
  index: number;
  dismiss(): void;
};

const iconData = {
  alert: {
    name: 'alert',
    color: '#FF3B30',
    backgroundColor: 'rgba(255,59,48,0.2)'
  },
  info: {
    name: 'information',
    color: '#007AFF',
    backgroundColor: 'rgba(0,122,255,0.2)'
  },
  warn: {
    name: 'warning',
    color: '#FFCC00',
    backgroundColor: 'rgba(255,204,0,0.2)'
  },
  success: {
    name: 'checkmark',
    color: '#55D696',
    backgroundColor: 'rgba(85,214,150,0.2)'
  },
  bug: {
    name: 'bug',
    color: '#FF3B30',
    backgroundColor: 'rgba(255,59,48,0.2)'
  }
};

const Notification = ({ message, type, title, index, dismiss }: Props) => {
  const [display, setDisplay] = useState(false);
  const [dismissing, setDismissing] = useState(false);

  const handleDismiss = () => {
    setDismissing(true);

    setTimeout(() => dismiss(), 200);
  };

  const handleDisplay = () => {
    setDisplay(true);

    setTimeout(() => handleDismiss(), 3200);
  };

  const icon = useMemo(() => iconData[type], [type]);

  useEffect(() => {
    handleDisplay();
  }, []);

  return (
    <div
      className={`${styles.container} ${display ? styles.display : ''} ${
        dismissing ? styles.dismissing : ''
      }`}
      style={{ top: `${index * 80 + 16 * (index + 1)}px` }}
    >
      <div
        className={styles.iconWrapper}
        style={{ backgroundColor: icon.backgroundColor, color: icon.color }}
      >
        {/* @ts-ignore */}
        <IonIcon name={icon.name} />
      </div>
      <div className={styles.infoWrapper}>
        {title ? <p className={styles.title}>{title}</p> : null}
        <p className={`${styles.message} ${title ? styles.withTitle : ''}`}>
          {message}
        </p>
      </div>
    </div>
  );
};

Notification.defaultProps = {
  title: ''
};

export default Notification;
