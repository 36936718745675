import type { ButtonHTMLAttributes } from 'react';

import styles from './Segment.module.scss';

type CustomProps = {
  text: string;
};

type Props = ButtonHTMLAttributes<HTMLButtonElement> & CustomProps;

const Segment = ({ text, className, ...buttonProps }: Props) => (
  <button
    type="button"
    {...buttonProps}
    className={`${styles.button} ${className}`}
  >
    {text}
  </button>
);

export default Segment;
