import { useRef, useState } from 'react';
import useEvent from './useEvent';

const devices = {
  smallPhone: 320,
  phone: 640,
  smallTablet: 758,
  tablet: 1078,
  desktop: 1078
};

type ReturnType = [
  (compareString: string) => boolean,
  {
    windowWidth: number;
  }
];

const useCompareDeviceWidth = (): ReturnType => {
  const [windowWidth, _setWindowWidth] = useState(window.innerWidth);
  const widthRef = useRef(window.innerWidth);

  const setWindowWidth = (newWidth: number) => {
    _setWindowWidth(newWidth);
    widthRef.current = newWidth;
  };

  const getDeviceWidth = (device: string) => {
    if (device.endsWith('px')) {
      const deviceWidth = parseInt(device.slice(0, -2), 10);
      return Number.isNaN(deviceWidth) ? 0 : deviceWidth;
    }

    // @ts-ignore
    const deviceWidth = devices[device];
    return deviceWidth || 0;
  };

  const compare = (compareString: string): boolean => {
    const operatorRegex = /^[=<>]+/;
    const deviceRegex = /[=<>]+([\s\S]*)$/;

    const deviceMatch = compareString.match(deviceRegex);
    const operatorMatch = compareString.match(operatorRegex);

    if (!operatorMatch || !deviceMatch || deviceMatch.length < 2) return false;

    const operator = operatorMatch[0];
    const device = deviceMatch[1];

    const deviceWidth = getDeviceWidth(device);

    if (!deviceWidth) return false;

    switch (operator) {
      case '<':
        return widthRef.current < deviceWidth;
      case '<=':
        return widthRef.current <= deviceWidth;
      case '>':
        return widthRef.current > deviceWidth;
      case '>=':
        return widthRef.current >= deviceWidth;
      default:
        return widthRef.current === deviceWidth;
    }
  };

  const handleResize = () => {
    if (widthRef.current !== window.innerWidth)
      setWindowWidth(window.innerWidth);
  };

  useEvent('resize', handleResize);

  return [compare, { windowWidth }];
};

export default useCompareDeviceWidth;
