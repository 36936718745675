import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useState
} from 'react';

import type { ReactNode } from 'react';

import styles from './InlineInputGroup.module.scss';

type ChildrenType = ReactNode[] | ReactNode;

type Props = {
  basis?: string | string[];
  spacing?: number;
  children?: ChildrenType;
};

const InlineInputGroup = ({ children, basis, spacing }: Props) => {
  const [customChildren, setCustomChildren] = useState<ChildrenType>();

  const getBasisForIndex = (index: number) => {
    if (!basis) return '50%';
    if (typeof basis === 'string') return basis;

    const count = Children.count(children);

    return index < count ? basis[index] : '0%';
  };

  const makeChildren = () => {
    const newChildren = Children.map(children, (child, index) => {
      let element = child;
      if (isValidElement(child)) {
        const flexBasis = `calc(${getBasisForIndex(index)} - ${spacing}px)`;

        element = cloneElement(child, {
          // @ts-ignore
          style: { flexBasis }
        });
      }

      if (index !== Children.count(children) - 1)
        return (
          <>
            {element}
            <div className={styles.verticalSpacer} />
          </>
        );

      return element;
    });

    setCustomChildren(newChildren);
  };

  useEffect(() => {
    makeChildren();
  }, [children]);

  return <div className={styles.container}>{customChildren}</div>;
};

InlineInputGroup.defaultProps = {
  basis: '50%',
  spacing: 8,
  children: null
};

export default InlineInputGroup;
