import IonIcon from '@reacticons/ionicons';
import styles from './Footer.module.scss';

type Props = {
  currentPage: number;
  totalPages: number;
  setPage?: (newPage: number) => void;
};

const Footer = ({ currentPage, totalPages, setPage }: Props) => {
  const changePage = (type: 'increment' | 'decrement') => () => {
    if (setPage)
      setPage(type === 'increment' ? currentPage + 1 : currentPage - 1);
  };

  return (
    <div className={styles.row}>
      <p className={styles.pages}>
        {currentPage} de {totalPages}
      </p>
      <button
        type="button"
        className={`${styles.paginationButton} ${
          currentPage <= 1 ? styles.disabled : ''
        }`}
        disabled={currentPage <= 1}
        onClick={changePage('decrement')}
      >
        <IonIcon name="chevron-back" />
      </button>
      <button
        type="button"
        className={`${styles.paginationButton} ${
          currentPage === totalPages ? styles.disabled : ''
        }`}
        disabled={currentPage === totalPages}
        onClick={changePage('increment')}
      >
        <IonIcon name="chevron-forward" />
      </button>
    </div>
  );
};

Footer.defaultProps = {
  setPage: undefined
};

export default Footer;
