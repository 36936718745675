/* eslint-disable no-confusing-arrow */
import { ChangeEvent, Fragment, useMemo, KeyboardEvent } from 'react';

import styles from './index.module.scss';

import { useProfileData } from '../../providers/ProfileDataProvider';

import Section from './Section';
import Input from '../Input';
import SegmentedControl from '../SegmentedControl';
import IconMultiSelect from '../IconMultiSelect';
import InlineInputGroup from '../InlineInputGroup';
import ChipInput from '../ChipInput';
import TextChipSelect from '../TextChipSelect';
import CheckBoxRow from '../CheckBoxRow';
import UploadFileRow from '../TransferFileRow';
import Select from '../Select';

import { Dictionary, TransferItem } from '../../types';

type Props = {
  transfers: Dictionary<TransferItem>;
  phoneOrSmaller?: boolean;
  handleTextChange(event: ChangeEvent<HTMLInputElement>): void;
  handleSegmentSelect(name: string, value: unknown, index: number): void;
  handleChange(name: string, value: unknown): void;
  handleFilesChange(files: FileList, id?: string): void;
  handleKeyDown(event: KeyboardEvent): void;
};

const interesSegments = [
  {
    text: 'Persona Física',
    value: 'Físico'
  },
  {
    text: 'Persona Moral',
    value: 'Moral'
  }
];

const paymentMethodItemsByInteres = {
  Físico: [
    {
      label: 'Tarjeta',
      key: 'card',
      icon: 'card'
    },
    {
      label: 'Efectivo',
      key: 'cash',
      icon: 'cash'
    }
  ],
  Moral: [
    {
      label: 'CoDi',
      key: 'codi',
      icon: 'qr-code'
    },
    {
      label: 'SPEI',
      key: 'spei',
      icon: 'business'
    },
    {
      label: 'Tarjeta',
      key: 'card',
      icon: 'card'
    },
    {
      label: 'Efectivo',
      key: 'cash',
      icon: 'cash'
    }
  ]
};

const requiredFilesByPaymentOptions = {
  spei: [
    'ine',
    'ineBack',
    'proofOfAddress',
    'accountStatus',
    'fiscalSituation',
    'fiscalSituationBusiness',
    'consitutiveAct',
    'publicRegistry',
    'powerOfAttorney',
    'fotoBusinessIn',
    'fotobusinessOut'
  ],
  default: ['ine', 'ineBack', 'proofOfAddress', 'accountStatus']
};

const fileLabelsByKey = {
  ine: 'INE - frente',
  ineBack: 'INE - reverso',
  fiscalSituation: 'Situación fiscal',
  fiscalSituationBusiness: 'Situación fiscal (Empresa)',
  accountStatus: 'Estado de cuenta',
  proofOfAddress: 'Comprobante de domicilio',
  consitutiveAct: 'Acta constitutiva',
  publicRegistry: 'Registro público',
  powerOfAttorney: 'Poder notarial',
  fotoBusinessIn: 'Foto del negocio por dentro',
  fotobusinessOut: 'Foto del negocio por fuera'
};

const businessOptions = [
  { label: 'travelAgency', value: 'Agencia de viajes' },
  { label: 'insurers', value: 'Aseguradoras' },
  { label: 'schoolsColleges', value: 'Colegios y Universidades' },
  { label: 'fastFood', value: 'Comida rápida' },
  { label: 'basicEducation', value: 'Educación básica' },
  { label: 'entertainment', value: 'Entretenimiento' },
  { label: 'parkingLots', value: 'Estacionamientos' },
  { label: 'pharmacies', value: 'Farmacias' },
  { label: 'gasStation', value: 'Gasolineras' },
  { label: 'government', value: 'Gobierno' },
  { label: 'dayCareCenters', value: 'Guarderias' },
  { label: 'hospitals', value: 'Hospitales' },
  { label: 'hotels', value: 'Hoteles' },
  { label: 'doctors', value: 'Médicos y dentistas' },
  { label: 'cornerShop', value: 'Miscelánea' },
  { label: 'tollFee', value: 'Peaje' },
  { label: 'hardwareRepairShop', value: 'Refacciones y ferreterías' },
  { label: 'restaurants', value: 'Restaurantes' },
  { label: 'hairSalon', value: 'Salones de belleza' },
  { label: 'markets', value: 'Supermercados' },
  { label: 'telecommunications', value: 'Telecomunicaciones' },
  { label: 'airTransport', value: 'Transporte aereo' },
  { label: 'groundTransportation', value: 'Transporte terrestre de pasajeros' },
  { label: 'retailSales', value: 'Ventas al menudeo' },
  { label: 'other', value: 'Otros' }
];

const turnoverOptions = [
  '$0 - $20,000 MXN',
  '$20,000 - $100,000 MXN',
  '$100,000 - $250,000 MXN',
  '+ $250,000 MXN'
];

const OnBoarding = ({
  transfers,
  phoneOrSmaller,
  handleTextChange,
  handleSegmentSelect,
  handleChange,
  handleFilesChange,
  handleKeyDown
}: Props) => {
  const { profileData, documentation } = useProfileData();

  const paymentMethodItems = useMemo(
    () =>
      // @ts-ignore
      paymentMethodItemsByInteres[profileData.interes] ||
      paymentMethodItemsByInteres['Físico'],
    [profileData.interes]
  );

  const requiredFiles = useMemo(() => {
    if (profileData.paymentOptions?.spei && profileData.interes === 'Moral')
      return requiredFilesByPaymentOptions.spei;
    return requiredFilesByPaymentOptions.default;
  }, [profileData.paymentOptions, profileData.interes]);

  const getProgressForId = (id: string) =>
    !transfers[id] ? 0 : transfers[id].progress;

  const getStatusForId = (id: string) => {
    if (!transfers[id] && documentation[id]) return 'done';
    return !transfers[id] ? 'none' : transfers[id].status;
  };

  const getDisabledForId = (id: string) => {
    if (!transfers[id]) return false;
    return (
      transfers[id].status === 'queue' || transfers[id].status === 'transfering'
    );
  };

  const renderUploadFileRows = () =>
    requiredFiles.map((fileKey, index) => (
      <Fragment key={fileKey}>
        <UploadFileRow
          id={fileKey}
          // @ts-ignore
          label={fileLabelsByKey[fileKey]}
          progress={getProgressForId(fileKey)}
          status={getStatusForId(fileKey)}
          disabled={getDisabledForId(fileKey)}
          style={{ flexBasis: 'calc(50% - 40px)' }}
          acceptFiles={
            fileKey !== 'fotoBusinessIn' && fileKey !== 'fotobusinessOut'
              ? 'application/pdf'
              : undefined
          }
          onFilesChange={handleFilesChange}
        />
        {(index + 1) % 2 === 0 ? (
          <div className={styles.fileSpacer} style={{ flexBasis: '100%' }} />
        ) : (
          <div className={styles.smallDeviceSpacer} />
        )}
      </Fragment>
    ));

  const renderBusinessOptions = () =>
    businessOptions.map(option => (
      <option key={option.label} value={option.value}>
        {option.value}
      </option>
    ));

  const handleSelect = ({
    target: { value }
  }: ChangeEvent<HTMLSelectElement>) => {
    handleChange('business.type', value);
  };

  return (
    <div id="obContainer" className={styles.container}>
      <Section
        title="Datos de Registro"
        notes={[
          'Email y contraseña con los cuales accedera a la plataforma de PayCode. Estos campos no son editables una vez creada su cuenta.'
        ]}
        topSpacer={false}
        id="logDataSection"
        phoneOrSmaller={phoneOrSmaller}
        observation={profileData.observations?.register}
      >
        <Input
          id="obEmailInput"
          placeholder="Email"
          name="email"
          value={profileData.email}
          disabled
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
        />
        <div className={styles.inputSpacer} />
        <Input
          id="obPasswordInput"
          placeholder="Password"
          name="password"
          value="sumpassword"
          type="password"
          onKeyDown={handleKeyDown}
          disabled
        />
      </Section>
      <Section
        title="Interés"
        notes={[
          'Personas físicas con actividad empresarial pueden escoger persona moral como interés para tener mas opciónes de pago.'
        ]}
        id="interestSection"
        phoneOrSmaller={phoneOrSmaller}
        observation={profileData.observations?.interes}
      >
        <SegmentedControl
          segments={interesSegments}
          value={profileData.interes}
          name="interes"
          onSelect={handleSegmentSelect}
        />
        <div className={styles.inputSpacer} />
        <p className={styles.question}>
          ¿Cómo le quieres cobrar a tus clientes?
        </p>
        <IconMultiSelect
          items={paymentMethodItems}
          itemsPerRow={4}
          name="paymentOptions"
          value={profileData.paymentOptions}
          onChange={handleChange}
        />
      </Section>
      <Section
        title="Datos Personales"
        notes={[
          'Si su interés es Persona Moral estos datos deben ser llenados por el representante legal de la empresa.'
        ]}
        id="personalInfoSection"
        phoneOrSmaller={phoneOrSmaller}
        observation={profileData.observations?.personal}
      >
        <Input
          id="obName"
          placeholder="Nombre(s)"
          name="name"
          value={profileData.name}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
        />
        <div className={styles.inputSpacer} />
        <InlineInputGroup basis="50%" spacing={8}>
          <Input
            id="obPaternalSurname"
            placeholder="Apellido paterno"
            name="lastName"
            value={profileData.lastName}
            onChange={handleTextChange}
            onKeyDown={handleKeyDown}
          />
          <Input
            id="obMaternalSurname"
            placeholder="Apellido materno"
            name="lastNameM"
            value={profileData.lastNameM}
            onChange={handleTextChange}
            onKeyDown={handleKeyDown}
          />
        </InlineInputGroup>
        <div className={styles.inputSpacer} />
        <Input
          id="obCurp"
          placeholder="CURP"
          name="curp"
          value={profileData.curp}
          maxLength={18}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
        />
        <div className={styles.inputSpacer} />
        <Input
          id="obPhone"
          placeholder="Teléfono"
          name="phone"
          type="number"
          value={profileData.phone}
          maxLength={10}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
        />
      </Section>
      <Section
        title="Datos de su negocio"
        id="businessInfoSection"
        phoneOrSmaller={phoneOrSmaller}
        observation={profileData.observations?.company}
      >
        <InlineInputGroup
          basis={profileData.interes === 'Moral' ? '50%' : '100%'}
          spacing={profileData.interes === 'Moral' ? 8 : 0}
        >
          {profileData.interes === 'Moral' ? (
            <Input
              id="obBusinessName"
              placeholder="Nombre de la empresa"
              name="business.name"
              value={profileData.business?.name}
              onChange={handleTextChange}
            />
          ) : null}
          <Input
            id="obDoingBusinessAs"
            placeholder="Nombre comercial (opcional)"
            name="business.alias"
            value={profileData.business?.alias}
            onChange={handleTextChange}
            onKeyDown={handleKeyDown}
            helpDescription="Este es el nombre con el que que le aparecera a sus clientes al momento del cobro"
            icon="card"
          />
        </InlineInputGroup>
        <div className={styles.inputSpacer} />
        <Input
          id="obRFC"
          placeholder="RFC"
          name="business.rfc"
          value={profileData.business?.rfc}
          maxLength={14}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
        />
        <div className={styles.inputSpacer} />
        <InlineInputGroup basis={['70%', '30%']}>
          <Input
            id="obAddress"
            placeholder="Dirección"
            name="business.address"
            value={profileData.business?.address}
            onChange={handleTextChange}
            onKeyDown={handleKeyDown}
          />
          <Input
            id="obZipCode"
            placeholder="Código Postal"
            name="business.zipCode"
            value={profileData.business?.zipCode}
            onChange={handleTextChange}
            onKeyDown={handleKeyDown}
          />
        </InlineInputGroup>
        <div className={styles.inputSpacer} />
        {profileData.interes === 'Moral' ? (
          <>
            <InlineInputGroup>
              <ChipInput
                id="obOtherOwners"
                placeholder="Otros dueños (opcional)"
                name="otherOwners"
                chips={profileData.otherOwners}
                onChipsChange={handleChange}
              />
              <ChipInput
                id="obOtherBusiness"
                placeholder="Otros negocios (opcional)"
                name="otherBusiness"
                chips={profileData.otherBusiness}
                onChipsChange={handleChange}
              />
            </InlineInputGroup>
            <div className={styles.inputSpacer} />
          </>
        ) : null}
        <Select
          id="obBusinessTypes"
          placeholder="Tipo de negocio"
          value={profileData.business?.type}
          onChange={handleSelect}
        >
          {renderBusinessOptions()}
        </Select>
      </Section>
      <Section
        title="Volumen de ventas"
        id="salesVolumeSection"
        phoneOrSmaller={phoneOrSmaller}
        observation={profileData.observations?.sales}
      >
        <InlineInputGroup>
          <Input
            id="obTopTicket"
            placeholder="Ticket tope"
            name="topTicket"
            value={profileData.topTicket}
            onChange={handleTextChange}
          />
          <Input
            id="obAvgTicket"
            placeholder="Ticket promedio"
            name="avgTicket"
            value={profileData.avgTicket}
            onChange={handleTextChange}
            onKeyDown={handleKeyDown}
          />
        </InlineInputGroup>
        <div className={styles.inputSpacer} />
        <p className={styles.question}>
          ¿Cuál es el volumen de las ventas mensuales que esperas?
        </p>
        <TextChipSelect
          chips={turnoverOptions}
          value={profileData.turnover}
          name="turnover"
          onChange={handleChange}
        />
      </Section>
      <Section
        title="Servicios y proveedores"
        id="providersSection"
        phoneOrSmaller={phoneOrSmaller}
        observation={profileData.observations?.services}
        notes={[
          'Para cobros digitales es necesario que proporcione una página web o una liga a su plataforma, app o servicio.'
        ]}
      >
        <ChipInput
          id="obDeliveryMethods"
          placeholder="Métodos de entrega (opcional)"
          name="deliveryMethods"
          chips={profileData.deliveryMethods}
          onChipsChange={handleChange}
        />
        <div className={styles.inputSpacer} />
        <Input
          id="obWebPageUrl"
          placeholder="URL de su página web (opcional)"
          name="webPageUrl"
          value={profileData.webPageUrl}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
        />
        <div className={styles.inputSpacer} />
        <Input
          id="obHostingService"
          placeholder="Servicio de hosting de su página web (opcional)"
          name="hostingService"
          value={profileData.hostingService}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
        />
        <div className={styles.inputSpacer} />
        <CheckBoxRow
          text="Acepta que no cuenta con ningun proveedor que tenga acceso a los datos de sus tarjetahabientes."
          checked={profileData.providerAccess}
          name="providerAccess"
          onChange={handleChange}
        />
        <div className={styles.inputSpacer} />
        <CheckBoxRow
          text="Acepta que no recibira ordenes directas pro Mo/To."
          checked={profileData.restrictedDirectOrders}
          name="restrictedDirectOrders"
          onChange={handleChange}
        />
      </Section>
      <Section
        title="Alta de archivos"
        hideDivider
        id="uploadFilesSection"
        phoneOrSmaller={phoneOrSmaller}
        observation={profileData.observations?.files}
        notes={[
          'Es importante que los documentos esten escaneados y en formato PDF, ya que el banco no acepta fotocopias, imágenes o capturas de pantalla.',
          'La vigencia de sus documentos debe de ser no mayor a 3 meses.',
          profileData.interes === 'Moral'
            ? 'Las fotos del negocio por dentro y por fuera son requeridas unicamente si su negocio tiene un local físico y/o va a cobrar con terminal punto de venta. Si su negocio es digital dichas fotos son opcionales.'
            : ''
        ]}
      >
        <div className={styles.fileRowWrapper}>{renderUploadFileRows()}</div>
      </Section>
    </div>
  );
};

OnBoarding.defaultProps = {
  phoneOrSmaller: false
};

export default OnBoarding;
