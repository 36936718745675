import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { ChangeEvent } from 'react';

import useFormErrors from '../hooks/useFormErrors';
import { useNotifications } from '../providers/NotificationProvider';
import Auth from '../lib/AuthService';
import Validator from '../lib/Validator';

import Landing from '../components/Landing';

const LandingContainer = () => {
  const [page, setPage] = useState<'continue' | 'signup'>('signup');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authorizing, setAuthorizing] = useState(false);
  const [errors, { clearError, setErrors }] = useFormErrors();
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const { notify } = useNotifications();

  const navigate = useNavigate();

  const toggleTerms = () => setAcceptedTerms(!acceptedTerms);

  const validateEmail = (value: string) => {
    const valid = Validator.email(value);

    if (!valid && !errors.email)
      setErrors({ ...errors, email: 'Email inválido' });
    else if (valid && errors.email) clearError('email');
  };

  const validatePassword = (value: string) => {
    const valid = page === 'continue' || Validator.password(value);

    if (!valid && !errors.password)
      setErrors({ ...errors, password: 'Contraseña inválida' });
    else if (valid && errors.password) clearError('password');
  };

  const handleTextChange = ({
    target: { name, value }
  }: ChangeEvent<HTMLInputElement>) => {
    switch (name) {
      case 'email':
        validateEmail(value);
        setEmail(value);
        break;
      case 'password':
        validatePassword(value);
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const togglePage = () => setPage(page === 'continue' ? 'signup' : 'continue');

  const getAuthNotificationErrorMessage = (message: string) => {
    if (message === 'Request failed with status code 401')
      return 'Email o contraseña incorrectos';
    if (
      message === 'El email ya existe.' ||
      message === 'El correo del administrador ya existe'
    )
      return 'Una cuenta con ese email ya existe';

    return 'Error desconocido, por favor intenta mas tarde o ponte en contacto con soporte';
  };

  const handleAuthException = (exception: unknown) => {
    const { message } =
      (exception as any).response.data || (exception as Error);

    if (message !== 'Error validando campos.')
      notify([
        {
          type: 'alert',
          title: 'Error al autorizar',
          message: getAuthNotificationErrorMessage(message)
        }
      ]);
  };

  const authorize = async () => {
    setAuthorizing(true);
    try {
      if (errors.email || errors.password || !email || !password) {
        if (errors.email || !email)
          notify([
            {
              title: errors.email || 'Email requerido',
              message: 'Por favor introduzca un email valido',
              type: 'alert'
            }
          ]);
        if (errors.password || !password)
          notify([
            {
              title: errors.password || 'Contraseña requerida',
              message:
                'La contraseña debe de tener 8 caracteres incluyendo números, minusculas y mayúsculas',
              type: 'alert'
            }
          ]);
        throw new Error('Error validando campos.');
      }

      if (page === 'continue') await Auth.login(email, password);
      else await Auth.signup({ email, password });

      navigate('/dashboard', { replace: true });
    } catch (exception) {
      handleAuthException(exception);
    } finally {
      setAuthorizing(false);
    }
  };

  useEffect(() => {
    if (page === 'continue') clearError('password');
    else if (page === 'signup' && password) validatePassword(password);
  }, [page]);

  return (
    <Landing
      page={page}
      email={email}
      password={password}
      errors={errors}
      acceptedTerms={acceptedTerms}
      authorizing={authorizing}
      handleTextChange={handleTextChange}
      togglePage={togglePage}
      authorize={authorize}
      toggleTerms={toggleTerms}
    />
  );
};

export default LandingContainer;
