import type { ChangeEvent } from 'react';

import styles from './index.module.scss';

import Input from '../../Input';
import { ReactComponent as Logo } from '../../../assets/images/paycodeLogo.svg';

type Props = {
  email: string;
  password: string;
  errors: Record<string, string>;
  authorizing: boolean;
  handleTextChange(event: ChangeEvent<HTMLInputElement>): void;
  authorize(): void;
};

const AdminAuth = ({
  email,
  password,
  authorizing,
  errors,
  handleTextChange,
  authorize
}: Props) => (
  <div className={styles.container}>
    <div className={styles.card}>
      <Logo width={204} className={styles.logo} />
      <Input
        id="landingEmail"
        name="email"
        placeholder="Email"
        type="email"
        value={email}
        error={errors.email}
        onChange={handleTextChange}
      />
      <div className={styles.inputSpacer} />
      <Input
        id="landingPassword"
        name="password"
        placeholder="Contraseña"
        value={password}
        error={errors.password}
        type="password"
        showVisibilityToggle
        onChange={handleTextChange}
      />
      <button
        className={styles.primaryButton}
        type="button"
        onClick={authorize}
        disabled={authorizing}
      >
        {!authorizing ? 'Login' : 'Autorizando...'}
      </button>
      <div className={styles.divider} />
      <button type="button" className={styles.message}>
        Este dashboard es para administradores y vendedores. Si desea entrar a
        nuestro onboarding para usuarios <span>haga click aqui.</span>
      </button>
    </div>
  </div>
);

export default AdminAuth;
