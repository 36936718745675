import { useState, ChangeEvent } from 'react';

import { useStoreData } from '../providers/CreateStoreProvider';

import CheckOut from '../components/CheckOut';

const initialFormData = {
  fullName: '',
  address: '',
  zipCode: '',
  city: '',
  sublocality: '',
  phone: ''
};

const Checkout = () => {
  const [billingData, setBillingData] = useState(initialFormData);
  const [shippingData, setShippingData] = useState(initialFormData);
  const { products, quantities } = useStoreData();
  const [useBillingData, setUseBillingData] = useState(true);

  const handleChange = ({
    target: { name, value }
  }: ChangeEvent<HTMLInputElement>) => {
    const [type, key] = name.split('/');

    if (type === 'billing') setBillingData({ ...billingData, [key]: value });

    if (type === 'shipping') setShippingData({ ...shippingData, [key]: value });
  };

  const handleCheckChange = () => {
    setUseBillingData(!useBillingData);
  };

  return (
    <CheckOut
      check={useBillingData}
      onCheckChange={handleCheckChange}
      onTextChange={handleChange}
      billingData={billingData}
      shippingData={shippingData}
      products={products}
      quantities={quantities}
    />
  );
};

export default Checkout;
