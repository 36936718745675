import { ButtonHTMLAttributes } from 'react';
import styles from './TextChip.module.scss';

type CustomProps = {
  text: string;
  selected: boolean;
};

type Props = ButtonHTMLAttributes<HTMLButtonElement> & CustomProps;

const TextChip = ({ text, selected, ...buttonProps }: Props) => (
  <button
    type="button"
    {...buttonProps}
    className={`${styles.chip} ${buttonProps.className} ${
      selected ? styles.selected : ''
    }`}
  >
    {text}
  </button>
);

export default TextChip;
