import {
  TypedUseSelectorHook,
  useSelector as useDefaultSelector,
  useDispatch as useDefaultDispatch
} from 'react-redux';

import type { RootState, AppDispatch } from '../store';

export const useDispatch = () => useDefaultDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useDefaultSelector;
