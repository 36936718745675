/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
import { ChangeEvent } from 'react';

import styles from './index.module.scss';

import Section from '../../OnBoarding/Section';
import Input from '../../Input';
import FormData from '../../FormData';
import TransferFileRow from '../../TransferFileRow';
import PaymentMethodCard from './PaymentMethodCard';
import InlineInputGroup from '../../InlineInputGroup';

import { Observations, ProfileData } from '../../../types';

type Props = {
  phoneOrSmaller?: boolean;
  observations: Observations;
  adminData: ProfileData;
  handleTextChange(event: ChangeEvent): void;
};

const fileLabelsByKey = {
  ine: 'INE',
  ineBack: 'INE - reverso',
  fiscalSituation: 'Situación fiscal',
  fiscalSituationBusiness: 'Situación fiscal (Empresa)',
  accountStatus: 'Estado de cuenta',
  proofOfAddress: 'Comprobante de domicilio',
  consitutiveAct: 'Acta constitutiva',
  publicRegistry: 'Registro público',
  powerOfAttorney: 'Poder notarial',
  fotoBusinessIn: 'Foto del negocio por dentro',
  fotoBusinessOut: 'Foto del negocio por fuera'
};

const ProfileScreen = ({
  phoneOrSmaller,
  handleTextChange,
  observations,
  adminData
}: Props) => {
  const renderDownloadButtons = () =>
    adminData.documentation?.map(doc =>
      doc[doc.id] ? (
        <TransferFileRow
          key={doc.id}
          id={doc.id}
          // @ts-ignore
          label={fileLabelsByKey[doc.id] || doc.id}
          style={{ flexBasis: 'calc(50% - 40px' }}
          s3Url={doc[doc.id]}
          download
        />
      ) : null
    );

  return (
    <div className={styles.container}>
      <Section
        title="Datos de Registro"
        id="adminLogData"
        phoneOrSmaller={phoneOrSmaller}
      >
        <FormData label="Email" value={adminData.email} />
        <div className={styles.spacer} />
        <Input
          id="observationLogData"
          name="register"
          placeholder="Observaciones datos de registro"
          onChange={handleTextChange}
          value={observations.register}
        />
      </Section>
      <Section
        title="Interés"
        id="adminInterest"
        phoneOrSmaller={phoneOrSmaller}
      >
        <FormData label="Régimen fiscal" value={adminData.interes} />
        <div className={styles.spacer} />
        <PaymentMethodCard
          items={adminData.paymentOptions || {}}
          title="Métodos de pago"
        />
        <div className={styles.spacer} />
        <Input
          id="observationInterest"
          name="interes"
          placeholder="Obsevaciones interés"
          onChange={handleTextChange}
          value={observations.interes}
        />
      </Section>
      <Section
        title="Datos personales"
        id="adminPersonalInfo"
        phoneOrSmaller={phoneOrSmaller}
      >
        <InlineInputGroup basis="33%" spacing={0}>
          <FormData label="Nombre" value={adminData?.name} />
          <FormData label="Apellido Paterno" value={adminData.lastName} />
          <FormData label="Apellido Materno" value={adminData.lastNameM} />
        </InlineInputGroup>
        <div className={styles.spacer} />
        <FormData label="CURP" value={adminData.curp} />
        <div className={styles.spacer} />
        <FormData label="Teléfono" value={adminData.phone} />
        <div className={styles.spacer} />
        <Input
          id="observationPersonalInfo"
          name="personal"
          placeholder="Observaciones datos personales"
          value={observations.personal}
          onChange={handleTextChange}
        />
      </Section>
      <Section
        title="Datos de su empresa"
        id="adminBusinessInfo"
        phoneOrSmaller={phoneOrSmaller}
      >
        <InlineInputGroup basis="50%" spacing={0}>
          <FormData
            label="Nombre de la Empresa"
            value={adminData.business?.name}
          />
          <FormData
            label="Nombre comercial"
            value={adminData.business?.alias}
          />
        </InlineInputGroup>
        <div className={styles.spacer} />
        <FormData label="RFC" value={adminData.business?.rfc} />
        <div className={styles.spacer} />
        <InlineInputGroup basis="50%" spacing={0}>
          <FormData label="Dirección" value={adminData.business?.address} />
          <FormData label="Código postal" value={adminData.business?.zipCode} />
        </InlineInputGroup>
        <div className={styles.spacer} />
        <FormData label="Otros dueños" value={adminData.otherOwners} />
        <div className={styles.spacer} />
        <FormData label="Otros negocios" value={adminData.otherBusiness} />
        <div className={styles.spacer} />
        <Input
          id="observationBusinessInfo"
          name="company"
          placeholder="Observaciones datos del negocio"
          onChange={handleTextChange}
          value={observations.company}
        />
      </Section>
      <Section title="Volumen de ventas" id="adminSalesVolume">
        <InlineInputGroup basis="33%" spacing={0}>
          <FormData label="Ticket tope" value={adminData.topTicket} />
          <FormData label="Ticket promedio" value={adminData.avgTicket} />
          <FormData label="Ventas esperadas" value={adminData.turnover} />
        </InlineInputGroup>
        <div className={styles.spacer} />
        <Input
          id="observationSalesVolume"
          name="sales"
          placeholder="Observaciones volumen de ventasx"
          onChange={handleTextChange}
          value={observations.sales}
        />
      </Section>
      <Section title="Servicios y proveedores" id="adminProviders">
        <FormData
          label="Métodos de entrega"
          value={adminData.deliveryMethods}
        />
        <div className={styles.spacer} />
        <InlineInputGroup basis="50%" spacing={0}>
          <FormData label="URL pagina web" value={adminData.webPageUrl} />
          <FormData
            label="Sevicio de hosting"
            value={adminData.hostingService}
          />
        </InlineInputGroup>
        <div className={styles.spacer} />
        <FormData
          id="question"
          label="Acepta que no cuenta con proveedores que tengan acceso a datos de los tarjetahabientes"
          value={adminData.providerAccess}
        />
        <div className={styles.spacer} />
        <FormData
          id="question"
          label="Acepta que no recibira ordenes directas por MO/TO"
          value={adminData.restrictedDirectOrders}
        />
        <div className={styles.spacer} />
        <Input
          id="observationProviders"
          name="services"
          placeholder="Observacioes servicios y proveedores"
          onChange={handleTextChange}
          value={observations.services}
        />
      </Section>
      <Section title="Archivos cargados" id="adminFiles" hideDivider>
        <div className={styles.fileRowWrapper}>{renderDownloadButtons()}</div>
        <div className={styles.spacer} />
        <Input
          id="observationFiles"
          name="files"
          placeholder="Observaciones archivos"
          onChange={handleTextChange}
          value={observations.files}
        />
      </Section>
    </div>
  );
};

ProfileScreen.defaultProps = {
  phoneOrSmaller: false
};

export default ProfileScreen;
