import { useEffect, useState } from 'react';
import styles from './index.module.scss';

import ItemElement from './Item';

import { PaymentOptions } from '../../types';

export type Item = {
  label: string;
  key: keyof PaymentOptions;
  icon: string;
};

type Props = {
  items: Item[];
  name?: string;
  value?: PaymentOptions;
  itemsPerRow?: number;
  onChange?: (name: string, newValue: Partial<PaymentOptions>) => void;
};

const IconMultiSelect = ({
  items,
  name,
  value,
  itemsPerRow,
  onChange
}: Props) => {
  const [_value, setValue] = useState<Partial<PaymentOptions>>({});

  const handleItemClick = (key: keyof PaymentOptions) => () => {
    const current = !!_value[key];
    const newValue = { ..._value, [key]: !current };

    if (onChange) onChange(name || '', newValue);
    if (value === undefined) setValue(newValue);
  };

  const handleInitialValue = () => {
    const initialValue: Partial<PaymentOptions> = {};

    items.forEach(item => {
      initialValue[item.key] = false;
    });

    setValue(initialValue);
  };

  const renderItems = () =>
    items.map(item => (
      <ItemElement
        key={item.key}
        label={item.label}
        selected={!!_value[item.key]}
        basis={100 / (itemsPerRow || 1)}
        icon={item.icon}
        onClick={handleItemClick(item.key)}
      />
    ));

  useEffect(() => {
    if (value === undefined) handleInitialValue();
  }, []);

  useEffect(() => {
    if (value !== undefined) setValue(value);
  }, [value]);

  return (
    <div
      className={styles.container}
      style={{
        justifyContent:
          items.length < (itemsPerRow || 1) ? 'space-evenly' : 'space-between'
      }}
    >
      {renderItems()}
    </div>
  );
};

IconMultiSelect.defaultProps = {
  value: undefined,
  itemsPerRow: 1,
  onChange: undefined,
  name: ''
};

export default IconMultiSelect;
