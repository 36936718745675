import { ButtonHTMLAttributes, useLayoutEffect, useRef, useState } from 'react';
import IonIcon from '@reacticons/ionicons';

import styles from './index.module.scss';

type CustomProps = {
  label: string;
  basis: number;
  icon: any;
  selected: boolean;
};

type Props = ButtonHTMLAttributes<HTMLButtonElement> & CustomProps;

const Item = ({ label, basis, selected, icon, ...buttonProps }: Props) => {
  const [height, setHeight] = useState('auto');
  const btnRef = useRef<HTMLButtonElement | null>(null);

  const setHeightFromWidth = () => {
    if (!btnRef.current) return;
    setHeight(`${btnRef.current.offsetWidth}px`);
  };

  useLayoutEffect(() => {
    if (btnRef.current && height === 'auto') setHeightFromWidth();
  }, [btnRef]);

  return (
    <button
      ref={btnRef}
      type="button"
      {...buttonProps}
      className={`${styles.item} ${buttonProps.className} ${
        selected ? styles.selected : ''
      }`}
      style={{ flexBasis: `calc(${basis}% - 8px)`, height }}
    >
      <IonIcon name="checkmark-circle" className={styles.selectedIcon} />
      <IonIcon name={icon} className={styles.icon} />
      <p className={styles.label}>{label}</p>
    </button>
  );
};

export default Item;
