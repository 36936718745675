import { useNavigate, useLocation } from 'react-router-dom';
import IonIcon from '@reacticons/ionicons';
import styles from './index.module.scss';

import TokenService from '../../lib/TokenService';
import { useDispatch } from '../../hooks/useRedux';
import { logout } from '../../slices/user';
import { useProfileData } from '../../providers/ProfileDataProvider';
// import { usePopups } from '../../providers/PopupProvider';
import useScrollHighlight from '../../hooks/useScrollHighlight';

import OptionRow from './OptionRow';
import NavButton from './NavButton';

import { ReactComponent as Logo } from '../../assets/images/paycodeLogo.svg';

type Props = {
  display?: boolean;
  toggleSidebar?: () => void;
};

const sections = [
  'logDataSection',
  'interestSection',
  'personalInfoSection',
  'businessInfoSection',
  'salesVolumeSection',
  'providersSection',
  'uploadFilesSection'
];

const SideBar = ({ display, toggleSidebar }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { profileData, completeMap } = useProfileData();
  // const { showPopup } = usePopups();
  const [highlitedIndex] = useScrollHighlight(sections);

  const navToOnboarding = () => {
    navigate('/dashboard');
  };

  // const navToCart = () => {
  //   navigate('/dashboard/cart');
  // };

  const redirectContact = () => {
    window.open('https://paycode.com.mx/contacto.html');
  };

  const handleLogout = () => {
    TokenService.remove();
    dispatch(logout());
  };

  // const showSellerReferenceModal = () => showPopup('sellerReference');

  return (
    <>
      <div className={`${styles.container} ${display ? styles.display : ''}`}>
        <div className={styles.top}>
          <button
            type="button"
            className={styles.closeButton}
            onClick={toggleSidebar}
          >
            <IonIcon name="close" />
          </button>
          <Logo className={styles.logo} />
          <NavButton
            text="On Boarding"
            iconName="person"
            active={pathname === '/dashboard'}
            onClick={navToOnboarding}
          />
          <OptionRow
            text="Datos de Registro"
            count={2}
            maxCount={2}
            hrefId="logDataSection"
            active={highlitedIndex === 0}
          />
          <OptionRow
            text="Interés"
            count={(completeMap.interes || {}).count || 0}
            maxCount={(completeMap.interes || {}).maxCount || 0}
            hrefId="interestSection"
            active={highlitedIndex === 1}
          />
          <OptionRow
            text="Datos Personales"
            count={(completeMap.personalInfo || {}).count || 0}
            maxCount={(completeMap.personalInfo || {}).maxCount || 0}
            hrefId="personalInfoSection"
            active={highlitedIndex === 2}
          />
          <OptionRow
            text="Datos de su empresa"
            count={(completeMap.businessInfo || {}).count || 0}
            maxCount={(completeMap.businessInfo || {}).maxCount || 0}
            hrefId="businessInfoSection"
            active={highlitedIndex === 3}
          />
          <OptionRow
            text="Volumen de Ventas"
            count={(completeMap.salesVolume || {}).count || 0}
            maxCount={(completeMap.salesVolume || {}).maxCount || 0}
            hrefId="salesVolumeSection"
            active={highlitedIndex === 4}
          />
          <OptionRow
            text="Servicios y Proveedores"
            count={(completeMap.providers || {}).count || 0}
            maxCount={(completeMap.providers || {}).maxCount || 0}
            hrefId="providersSection"
            active={highlitedIndex === 5}
          />
          <OptionRow
            text="Alta de archivos"
            count={(completeMap.files || {}).count || 0}
            maxCount={(completeMap.files || {}).maxCount || 0}
            hrefId="uploadFilesSection"
            active={highlitedIndex === 6}
          />
          {/* <button
            type="button"
            className={styles.sellerRefButton}
            onClick={showSellerReferenceModal}
          >
            Referencia del vendedor
          </button> */}
          {/* <div className={styles.divider} />
          <NavButton
            text="Tienda"
            iconName="cart"
            active={pathname === '/dashboard/cart'}
            onClick={navToCart}
          /> */}
        </div>
        <div className={styles.bottom}>
          <p className={styles.name}>
            {profileData.name || ''} {profileData.lastName || ''}
          </p>
          <div className={styles.footer}>
            <button
              type="button"
              className={`${styles.button} ${styles.contact}`}
              onClick={redirectContact}
            >
              <IonIcon className={styles.chatIcon} name="chatbubble" />
              <p className={styles.textButton}>Contacto</p>
            </button>
            <button
              type="button"
              className={`${styles.button} ${styles.logout}`}
              onClick={handleLogout}
            >
              <IonIcon className={styles.chatIcon} name="exit" />
              <p className={styles.textButton}>Cerrar Sesion</p>
            </button>
          </div>
        </div>
      </div>
      {display ? (
        <button
          type="button"
          className={styles.scrim}
          aria-label="Scrim"
          onClick={toggleSidebar}
        />
      ) : null}
    </>
  );
};

SideBar.defaultProps = {
  display: true,
  toggleSidebar: undefined
};

export default SideBar;
