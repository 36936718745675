import _ from 'lodash';
import type {
  Profile,
  SerializedBusinessData,
  SerializedBusiness,
  SerializedBranch,
  Branch,
  ProfileData,
  SerializedBranchTerminal,
  BranchTerminal,
  SerializedZone
} from '../types';

type ParseType = 'profile' | 'profiles' | 'parseAdmin' | 'branches' | 'branch';

type ParseDataType =
  | SerializedBusinessData
  | SerializedBusinessData[]
  | SerializedBusiness;

type ParseReturnType = Profile | Profile[] | ProfileData | null;

const parseBranchTerminal = (
  serialTerminal: SerializedBranchTerminal
): BranchTerminal => {
  if (!serialTerminal || _.isArray(serialTerminal))
    return { id: '', type: '', status: '' };

  const { terminal_id, terminal_type, ...restTerminal } = serialTerminal;

  return {
    id: terminal_id,
    type: terminal_type,
    ...restTerminal
  };
};

export default class ApiParser {
  static parseZone(serialZone: SerializedZone) {
    const { id, zone_code, name } = serialZone;
    return { id, code: zone_code, name };
  }

  static parseZones(serialZones: SerializedZone[]) {
    return serialZones.map(serialZone => this.parseZone(serialZone));
  }

  static parseBranch(serialBranch: SerializedBranch): Branch {
    const {
      branch_code,
      short_name,
      terminal: serialTerminal,
      ...restBranch
    } = serialBranch;

    const terminal = parseBranchTerminal(serialTerminal);

    return {
      code: branch_code,
      shortName: short_name,
      ...restBranch,
      terminal
    };
  }

  static parseBranches(serialBranches: SerializedBranch[]): Branch[] {
    return serialBranches.map(serialBranch => this.parseBranch(serialBranch));
  }

  static parseProfile(data: SerializedBusinessData): Profile {
    const {
      onboarding_user: { name, paternal_lastName, maternal_lastName },
      business: { id, name: businessName, email, status }
    } = data;

    return {
      id,
      fullName: `${name} ${paternal_lastName} ${maternal_lastName}`,
      email,
      businessName,
      status
    };
  }

  static parseProfiles(data: SerializedBusinessData[]): Profile[] {
    return data.map(serialData => this.parseProfile(serialData));
  }

  static parseAdminData(data: SerializedBusiness): ProfileData {
    const {
      email,
      administrators,
      configuration,
      name: businessName,
      short_name: alias,
      rfc,
      address,
      zipCode,
      otherOwners,
      otherBusiness,
      topTicket,
      avgTicket,
      turnover,
      deliveryMethods,
      webPageUrl,
      hostingService,
      providerAccess,
      restrictedDirectOrders,
      status,
      documentation,
      observations,
      type_business: type
    } = data;

    const {
      firstName: name,
      lastName,
      lastNameM,
      interes,
      curp,
      phone
    } = administrators[0];
    const {
      payment_methods: { card, codi, spei, cash_in: cash }
    } = configuration;

    const filteredDocs = (documentation || []).filter(
      ({ type_doc }: any) => !!type_doc
    );
    const parsedDocs = filteredDocs.map(
      ({ valid, url_file, type_doc, name: docName }: any) => ({
        [type_doc]: url_file,
        valid,
        name: docName,
        id: type_doc
      })
    );

    return {
      email,
      phone,
      interes,
      name,
      lastName,
      lastNameM,
      curp,
      status,
      avgTicket,
      topTicket,
      webPageUrl,
      hostingService,
      deliveryMethods,
      otherBusiness,
      otherOwners,
      providerAccess: providerAccess === '1',
      restrictedDirectOrders: restrictedDirectOrders === '1',
      paymentOptions: {
        card,
        spei,
        codi,
        cash
      },
      business: {
        name: businessName,
        address,
        rfc,
        zipCode,
        type,
        alias
      },
      documentation: parsedDocs,
      turnover,
      observations
    };
  }

  static parse(data: ParseDataType, type: ParseType): ParseReturnType {
    switch (type) {
      case 'profile':
        return this.parseProfile(data as SerializedBusinessData);
      case 'profiles':
        return this.parseProfiles(data as SerializedBusinessData[]);
      case 'parseAdmin':
        return this.parseAdminData(data as SerializedBusiness);
      default:
        return null;
    }
  }
}
