/* eslint-disable no-confusing-arrow */
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import axios from 'axios';

import useCompareDeviceWidth from '../../hooks/useCompareDeviceWidth';

import Nav from '../../components/Nav';
import SideBar from '../../components/Admin/SideBar';
import Profiles from './Profiles';
import TokenService from '../../lib/TokenService';
import Profile from './Profile';

const AdminRouter = () => {
  const location = useLocation();
  const [compare] = useCompareDeviceWidth();
  const [searchTerm, setSearchTerm] = useState('');
  const [displaySidebar, setDisplaySidebar] = useState(compare('>tablet'));

  const withSearchBar = useMemo(
    () => location.pathname === '/admin/profiles',
    [location]
  );

  const searchBarPlaceholder = useMemo(
    () =>
      location.pathname === '/admin/profiles'
        ? 'Buscar por nombre de negocio'
        : '',
    [location]
  );

  const handleSearchChange = (newTerm: string) => setSearchTerm(newTerm);

  const toggleSideBar = () => setDisplaySidebar(!displaySidebar);

  useLayoutEffect(() => {
    const href = location.hash.split('#')[1];
    const element = document.getElementById(href);
    let timeout: any;

    if (location.pathname !== '/admin/profile') window.scrollTo(0, 0);
    if (location.hash && element) {
      timeout = setTimeout(() => {
        const offset = element.offsetTop;
        window.scrollTo({ top: offset - 56, left: 0, behavior: 'smooth' });
      }, 25);
    }

    if (searchTerm) setSearchTerm('');

    return () => {
      if (timeout) window.clearTimeout(timeout);
    };
  }, [location]);

  useEffect(() => {
    const token = TokenService.get(true);
    if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }, []);

  return (
    <>
      <SideBar short={location.pathname === '/admin/profile'} />
      <Nav
        toggleSidebar={toggleSideBar}
        paddingLeft={!displaySidebar}
        withSearchBar={withSearchBar}
        searchBarProps={{
          searchTerm,
          onSearchChange: handleSearchChange,
          placeholder: searchBarPlaceholder
        }}
      />
      <Routes>
        <Route
          path="/profiles"
          element={<Profiles searchTerm={searchTerm} />}
        />
        <Route
          path="/profile"
          element={<Profile displaySideBar={displaySidebar} />}
        />
      </Routes>
    </>
  );
};

export default AdminRouter;
